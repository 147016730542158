import React, { useContext, useEffect, useState } from "react";

import "firebase/firestore";
import { useFirebaseApp, useUser } from "reactfire";

import moment from "moment/min/moment-with-locales";

import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import PagNumber from "../pagination/pagination";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { UserContext } from "../../store/UserContext";

const MessagesList = (props) => {
  const { userData } = useContext(UserContext);

  const user = useUser();
  moment.globalLocale = "es";
  // Set foramto de fecha y hora
  moment.locale("es");
  const db = useFirebaseApp().firestore();

  /****************************************************
   * Constantes para la paginacion
   */
  const postsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [tweens, setTweens] = useState(userData && userData.rol === "admin");

  useEffect(() => {
    if (
      userData &&
      userData._userData &&
      userData._userData.rol === "admin"
      // (user.email === "hugo.rivero@montevideo.com.uy" ||
      //   user.email === "vrrm@adinet.com.uy")
    ) {
      setTweens(true);
    }
  });
  /*PageNumber*/
  const paginate = (pageNumber) => {
    const iolp = pageNumber * postsPerPage;
    const iofp = iolp - postsPerPage;
    const cp = list.slice(iofp, iolp);
    setCurrentPosts(cp);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    obtenerPosts();
  }, [list]);

  /**
   * Metodo que obteiene la lista en la plataforma
   */
  const obtenerPosts = async () => {
    //SI esta logueado
    if (user) {
      if (list) {
        const iolp = 1 * postsPerPage;
        const iofp = iolp - postsPerPage;
        const cp = list.slice(iofp, iolp);

        //setList(list);
        setCurrentPosts(cp);
      } else {
        goHome();
      }
    }
  };

  const getData = async () => {
    if (userData && userData._userData && userData._userData.rol === "admin") {
      db.collection("message")
        .orderBy("fecharegistro", "desc")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            if (
              userData &&
              userData._userData &&
              userData._userData.rol === "admin"
            ) {
              docs.push({ ...doc.data(), id: doc.id });
            }
          });
          setOriginalList(docs);
          setList(docs);
        });
    } else {
      goHome();
    }
  };

  //   const deleteObituary = (id) => {
  //     if (user) {
  //       confirmAlert({
  //         title: "Confirmar eliminación",
  //         message: "¿Desea eliminar la publicación?.",
  //         buttons: [
  //           {
  //             label: "Si",
  //             onClick: () =>
  //               db.collection("obituary").doc(id).update({ deleted: true }),
  //           },
  //           {
  //             label: "No",
  //           },
  //         ],
  //       });
  //     }
  //   };

  //   const editObituary = (servicio) => {
  //     setServicioActual(servicio);
  //     toggleInfoModal();
  //   };

  //   const addObituary = (servicio) => {
  //     if (user) {
  //       history.push("/formulario/condolencia/" + servicio.deceasedId);
  //     }
  //   };

  useEffect(() => {
    getData();
  }, [userData]);

  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Mensajes");
  });

  const history = useHistory();
  const goHome = () => history.push("/");

  /*********************
   * Modal Innfo Obituario
   */
  //   const [modalInfo, setModalInfo] = useState(false);
  //   const [servicioActual, setServicioActual] = useState("");
  //   const toggleInfoModal = () => {
  //     setModalInfo(!modalInfo);
  //   };
  //   const handleChangeServicioActual = (e) => {
  //     setServicioActual({ ...servicioActual, obit: e.target.value });
  //   };
  //   const handleChangeNombre = (e) => {
  //     setServicioActual({ ...servicioActual, nombre: e.target.value });
  //   };
  //   const handleChange = (e) => {
  //     setServicioActual({ ...servicioActual, ico: e.target.value });
  //   };

  //   const updateServicioActual = () => {
  //     toggleInfoModal();
  //     db.collection("obituary").doc(servicioActual.id).update(servicioActual);
  //   };

  /***********************************************************
   * Variables para el manejo del Input SEARCH
   * variable de estado y handleChange
   */

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    //var post = totalDatos.slice(0, totalDatos.length);
    setList(filterItemsObituary(originalList, e.target.value));
  };

  const filterItemsObituary = (p, query) => {
    if (query) {
      return p.filter((el) => {
        return (
          moment(el.fecharegistro.toDate())
            .format("DD-MM-YYYY")
            .indexOf(query) > -1 ||
          el.email.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.mensaje.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.nombre.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.telefono.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    } else {
      return p;
    }
  };

  return (
    <div>
      {tweens &&
        userData &&
        userData._userData &&
        userData._userData.rol === "admin" && (
          <div>
            {/* SEARCH BOX */}
            <div className="form-group">
              <input
                className="form-control "
                name="search"
                type="text"
                placeholder="Buscar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            {/* OBITUARY LIST */}
            {/* DESKTOP */}
            <div className="d-none d-lg-block d-xl-block">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Registro</th>
                    <th scope="col">Email</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Mensaje</th>
                    <th scope="col">Teléfono</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((servicio) => {
                    return (
                      <tr className="table-light" key={servicio.id}>
                        <td>
                          {moment(servicio.fecharegistro.toDate()).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </td>
                        <td>{servicio.email}</td>
                        <td>{servicio.nombre}</td>
                        <td>{servicio.mensaje}</td>
                        <td>{servicio.telefono}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* SMALL DEVICE SCREEN */}
            <div className="form-group d-lg-none">
              {currentPosts.map((servicio) => {
                return (
                  <div className="card border-success mb-3" key={servicio.id}>
                    <div className="card-header">
                      <div>{servicio.email}</div>
                      <div>{servicio.telefono}</div>
                      <div>
                        {moment(servicio.fecharegistro.toDate()).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      <h4 class="card-title">{servicio.nombre}</h4>
                      <p className="card-text">{servicio.mensaje}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/*PAGINATION*/}
            <Row className="d-flex justify-content-center">
              <PagNumber
                postsPerPage={postsPerPage}
                totalPosts={list.length}
                paginate={paginate}
                currentPage={currentPage}
              ></PagNumber>
            </Row>
          </div>
        )}
    </div>
  );
};

export default MessagesList;
