import React, { useState, useEffect, useContext } from "react";
import "firebase/firestore";
import "firebase/app";
import "firebase/auth";
import { useDatabase, useFirebaseApp, useUser } from "reactfire";
import { useHistory, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
} from "reactstrap";
import { FaCross, FaStarOfDavid, FaRibbon } from "react-icons/fa";
import { GiConsoleController, GiHood, GiShutRose } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import ObituaryEdit from "./ObituaryEdit";
import Obituary from "./Obituary";
import moment from "moment";
import "moment/locale/es";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserContext } from "../../store/UserContext";
import PaypalCheckoutButton from "../payment/PayPalCheckoutButton";
import PayPal from "../payment/PayPal";
import MercadoLibreIcon from "../../img/MercadoLibreIcon";
import ObituaryCompleteForm from "./ObituaryCompleteForm";

//import WrapperConsumer from "../../store/context";
//import * as firebase from "firebase/app";

const ObituaryForm = (props) => {
  moment.locale("es");
  var { userData } = useContext(UserContext);

  const [tweens, setTweens] = useState();
  useEffect(() => {
    setTweens(
      userData && userData._userData && userData._userData.rol === "admin"
    );
  }, [userData]);

  let history = useHistory();
  const goBack = () => history.goBack();
  const goHome = () => history.push("/");
  const db = useFirebaseApp().firestore();

  const mainTitle = { title: "Formulario de condolencias", smview: true };
  const [alert, setAlert] = useState("");
  const [obituary, setObituary] = useState({
    nombre: "",
    deceasedId: "",
    id: "",
    obitSelected: "",
    paymentId: "",
    paymentStatus: "",
  });
  const [obituryIdSave, setObituaryIdSave] = useState("");

  // const order = {
  //   customer: "123456",
  //   total: "5.50",
  //   items: [
  //     {
  //       sku: "112",
  //       name: "Obituario",
  //       price: "5.00",
  //       quantity: 1,
  //       currency: "USD",
  //     },
  //   ],
  // };
  // const [orderData, setOrderData] = useState(order);

  const [uyCountry, setUyCountry] = useState(false);
  const [paymentBtnId, setpaymentBtnId] = useState("");
  const [loader, setLoader] = useState(false);
  const { paramId } = useParams();

  const initialMensajes = {
    m1:
      "Falleció en la Paz del Señor, confortado con los santos sacramentos, el día ",
    m2: "Falleció en la Paz del Señor, el día ",
    m3: "Falleció el día ",
    m4: "Falleció el día ",
    f1: " y demás deudos participan con profundo dolor de dicho fallecimiento",
    f2: " y demás deudos participan de dicho fallecimiento",
    f3: " participan de dicho fallecimiento",
    f4:
      " participan con profundo dolor del fallecimiento y su sepelio ya efectuado",
  };
  const [mensajes] = useState(initialMensajes);

  function onChangeCaptcha(value) {
    setObituary({ ...obituary, captcha: value });
    saveCondolencia(obituary);
    setMessage("");
  }

  const getObituary = async () => {
    if (paramId) {
      const doc = await db.collection("deceased").doc(paramId).get();
      if (doc.data() && doc.data().deleted !== true) {
        const data = {
          ...doc.data(),
          deceasedId: paramId,
          // fecharegistro: app.firestore.Timestamp.fromDate(new Date(Date.now())),
        };
        setObituary({ ...obituary, ...data, quienes: "" });
      } else {
        window.alert("Lo sentimos el servicio ya no esta disponible.");
        goBack();
      }
    }
  };

  useEffect(() => {
    // console.log("CONTEXT:", props);
    const setmain = props.handleMain;
    setmain(mainTitle.title, mainTitle.smview);
  }, []);

  /*******************************************************************
   * Metodo que mediante una ip propia nos retorna la ip del cliente   *
   */
  const getIp = async () => {
    try {
      const res = await fetch(
        "https://us-central1-golit-84cb0.cloudfunctions.net/app/golit_geo"
      );
      const data = await res.json();
      //console.log("IP: ", data.your);
      return data.your;
    } catch {
      return "";
    }
  };

  /************************************************************************
   * Dada una ip obtenemso el nombre del pais mediante al acceso a GEOPLUGIN.net
   */
  const getCountry = async (ip) => {
    try {
      const res = await fetch(
        "https://ssl.geoplugin.net/json.gp?k=11d89140b7272c83&ip=" + ip
      );
      const data = await res.json();
      //console.log("data: ", data);
      const IsUY = data && data.geoplugin_currencyCode === "UYU";
      //console.log(IsUY);
      setUyCountry(IsUY);
    } catch {
      setUyCountry(false);
    }
  };

  /*************************************************
   * Al iniciar el componente obtenemos la ip y con esta el pais del usuario
   */
  useEffect(() => {
    const ip = getIp();
    if (ip) {
      getCountry(ip);
    } else {
      setUyCountry(false);
    }
  }, []);

  useEffect(() => {
    getObituary();
  }, []);

  const handleChange = (e) => {
    //opcion m5="Crear nuevo"
    setAlert("");
    var tempObituary = { ...obituary };
    if (e.target.name === "nombre") {
      const nameUpper = e.target.value.toUpperCase();
      tempObituary = { ...tempObituary, [e.target.name]: nameUpper };
    } else {
      tempObituary = { ...tempObituary, [e.target.name]: e.target.value };
    }
    // /****TRATAMIENTO DE MENSAJES */

    var tempData = moment(obituary.fehcafallecimiento).format("LL");
    var tempDataSepelio = moment(obituary.fechasepelio).format("LL");
    var tempObit = obituary.obit;
    if (
      e.target.value === "m1" ||
      (e.target.name === "quienes" && tempObituary.obitSelected === "m1")
    ) {
      tempObit =
        mensajes.m1 +
        " " +
        tempData +
        ", " +
        tempObituary.quienes +
        " " +
        mensajes.f1;
    }
    if (
      e.target.value === "m2" ||
      (e.target.name === "quienes" && tempObituary.obitSelected === "m2")
    ) {
      tempObit =
        mensajes.m2 +
        " " +
        tempData +
        ", " +
        tempObituary.quienes +
        " " +
        mensajes.f2;
    }
    if (
      e.target.value === "m3" ||
      (e.target.name === "quienes" && tempObituary.obitSelected === "m3")
    ) {
      tempObit =
        mensajes.m3 +
        " " +
        tempData +
        ", " +
        tempObituary.quienes +
        " " +
        mensajes.f3;
    }
    if (
      e.target.value === "m4" ||
      (e.target.name === "quienes" && tempObituary.obitSelected === "m4")
    ) {
      tempObit =
        mensajes.m4 +
        " " +
        tempData +
        ", " +
        tempObituary.quienes +
        " " +
        mensajes.f4;
    }

    if (
      obituary.fechasepelio &&
      (e.target === "m3" || e.target === "m2" || e.target === "m1")
    ) {
      tempObit =
        tempObit +
        " e invitan al sepelio a efectuarse el " +
        tempDataSepelio +
        ".";
    } else if (e.target === "m4") {
      tempObit = tempObit + ".";
    }
    if (
      e.target.value === "m5" ||
      (e.target.name === "quienes" && tempObituary.obitSelected === "m5")
    ) {
      tempObit = "";
    }
    // setMensajes(temp);
    setObituary({ ...tempObituary, obit: tempObit });
  };

  // Ejemplo implementando el metodo POST:
  async function putData(url = "", data = {}) {
    // Opciones por defecto estan marcadas con un *
    const response = await fetch(url, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      //mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "PUT",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      //redirect: 'follow', // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(response.data); // parses JSON response into native JavaScript objects
  }

  const saveDataObituary = async (obituary, paymentStatus) => {
    const now = Date.now();
    const created = firebase.firestore.Timestamp.fromDate(new Date(now));
    var userUid = "";
    var userEmail = "";

    if (user) {
      userUid = user.uid;
      userEmail = user.email.split("@")[0];
    }
    var userid = "";
    var empresaid = "";
    var username = "";
    if (userData && userData._userData && userData._userData.id) {
      userid = userData._userData.id;
      empresaid = userData._userData.empresa;
      username = userData._userData.nombre;
    }
    const result = await db.collection("obituary").add({
      ...obituary,
      fecharegistro: created,
      uid: userUid,
      user: userEmail,
      userid: userid,
      empresaid: empresaid,
      username: username,
      paymentStatus: paymentStatus,
    });
    setObituaryIdSave(result.id);
    return result;
  };

  const saveCondolencia = async (obituary) => {
    try {
      setLoader(true);
      const result = await saveDataObituary(obituary, "");
      const mercadoPago = await putData(
        "https://us-central1-golit-84cb0.cloudfunctions.net/app/api/payment1/" +
          result.id,
        {}
      );
      setLoader(false);
      setpaymentBtnId(mercadoPago.init_point);
    } catch (error) {}
  };

  const [btnEnable, setBtnEnable] = useState(true);

  /******
   * Metodo que captura las acciones del modal y lo apaga
   */
  const firebase = useFirebaseApp();
  const user = useUser();

  const initialValues = {
    email: "",
    password: "",
  };
  const [values, setValues] = useState(initialValues);
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState({ modal: false, isOpen: true });

  const toggleModal = () => {
    setValues(initialValues);
    setMessage("");
    setModal({
      modal: !modal.modal,
      isOpen: false,
    });
  };

  const goMLPayment = () => history.push({ paymentBtnId });
  const btnAceptarClick = async () => {
    try {
      //console.log(obituary.captcha, paymentBtnId);
      if (paymentBtnId) {
        setMessage("");
        /********************************************************************************************************* */
        goMLPayment();
        //GO PAYMENT MP
      } else {
        setMessage(
          "Debe marcar la casilla de verificación, para indicar que acepta los términos y condiciones."
        );
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  const [business, setBusiness] = useState();
  const isBusinessRol = () => {
    if (user) {
      db.collection("users")
        .where("email", "==", user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setBusiness(
              doc.data().rol === "admin" || doc.data().rol === "business"
            );
          });
        });
    }
  };
  useEffect(() => {
    isBusinessRol();
  });

  const saveObituaryBusinessConfirm = () => {
    if (user) {
      confirmAlert({
        title: "Confirmar publicación",
        message: "¿Confirma la publicación de la nueva nota?.",
        buttons: [
          {
            label: "Publicar",
            onClick: () => {
              saveDataObituary(obituary, "approved");
              goBack();
            },
          },
          {
            label: "Cancelar",
          },
        ],
      });
    }
  };

  const saveCondolenciaPayPal = async () => {
    if (obituary.quienes && obituary.obit) {
      try {
        saveDataObituary(obituary, "approved");
        goHome();
      } catch (error) {}
    } else {
      setAlert("Debe indicar un nombre y seleccionar una opcion de mensaje");
    }
  };

  const saveCondolenciaNonUser = async () => {
    if (obituary.quienes && obituary.obit) {
      try {
        toggleModal();
      } catch (error) {}
    } else {
      setAlert("Debe indicar un nombre y seleccionar una opcion de mensaje");
    }
  };

  const saveCondolenciaBusiness = async () => {
    if (
      (obituary.quienes && obituary.obit) ||
      (obituary.obit && obituary.obitSelected === "m5")
    ) {
      try {
        saveObituaryBusinessConfirm();
      } catch (error) {}
    } else {
      if (obituary.obitSelected === "m5") {
        setAlert("Debe ingresar un mensaje");
      } else {
        setAlert(
          "Debe indicar un nombre y seleccionar la opción del mensaje predefinido, o crear uno nuevo seleccionando 'Crear nuevo'"
        );
      }
    }
  };

  return (
    <div>
      <Modal isOpen={modal.modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Terminos y condiciones</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            {uyCountry && (
              <ReCAPTCHA
                sitekey="6LeDGNsZAAAAACp9xecibc6dKDfxxDo3P_R4bsWD"
                onChange={onChangeCaptcha}
              />
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div>
              {!loader && (
                <div>
                  Declaro que he leído y acepto los términos y condiciones.{" "}
                  {uyCountry
                    ? "Costo por publicación $U 280."
                    : "Costo por publicación USD 5.89."}
                </div>
              )}
              {loader && (
                <div>
                  Declaro que he leído y acepto los términos y condiciones.{" "}
                  {uyCountry
                    ? "Costo por publicación $U 280."
                    : "Costo por publicación USD 5.89."}
                  <Spinner size="sm" color="primary" />
                </div>
              )}
            </div>
          </div>
          <hr></hr>
          {paymentBtnId && (
            <div className="">
              <div className="">
                {uyCountry && (
                  <div className="block myButton">
                    <a href={paymentBtnId}>
                      <p className="d-flex justify-content-center ">
                        <MercadoLibreIcon></MercadoLibreIcon>
                      </p>
                      <button
                        className="myMercadoPagoButton col-12"
                        block="true"
                      >
                        Pagar con Mercado Pago
                      </button>
                    </a>
                  </div>
                )}
              </div>
              <div className="">
                {!uyCountry && (
                  <PayPal saveCondolenciaPayPal={saveCondolenciaPayPal} />
                )}
              </div>
            </div>
          )}
          {!paymentBtnId && (
            <div className="">
              <div className="">
                {uyCountry && (
                  <div className="myButton" onClick={btnAceptarClick}>
                    <p className="d-flex justify-content-center ">
                      <MercadoLibreIcon></MercadoLibreIcon>
                    </p>
                    <button className="myMercadoPagoButton col-12" block="true">
                      Pagar con Mercado Pago
                    </button>
                  </div>
                )}
              </div>
              <div className="">
                {!uyCountry && (
                  <PayPal saveCondolenciaPayPal={saveCondolenciaPayPal} />
                )}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>{message && <p>{message}</p>}</ModalFooter>
      </Modal>

      <div>
        <div className="form-group">
          <label>
            <strong>Nombre</strong>
          </label>
          <p
          // type="text"
          // className="form-control"
          // name="nombre"
          // value={obituary.nombre}
          ></p>
          {obituary.nombre}
        </div>

        <div className="form-group">
          <div className="row p-2">
            {/*faCross*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.ico === "FaCross"}
                    value="FaCross"
                    onChange={handleChange}
                    name="ico"
                    id="optionsRadios1"
                  />
                  <h3>
                    <FaCross />
                  </h3>
                </label>
              </div>
            </div>

            {/*FaStarOfDavid*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.ico === "FaStarOfDavid"}
                    value="FaStarOfDavid"
                    onChange={handleChange}
                    name="ico"
                    id="optionsRadios2"
                  />
                  <h3>
                    <FaStarOfDavid />
                  </h3>
                </label>
              </div>
            </div>
            {/*faRibbon*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.ico === "FaRibbon"}
                    value="FaRibbon"
                    onChange={handleChange}
                    name="ico"
                    id="optionsRadios5"
                  />
                  <h3>
                    <FaRibbon />
                  </h3>
                </label>
              </div>
            </div>

            {/*GiShutRose*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.ico === "GiShutRose"}
                    value="GiShutRose"
                    onChange={handleChange}
                    name="ico"
                    id="optionsRadios3"
                  />
                  <h3>
                    <GiShutRose />
                  </h3>
                </label>
              </div>
            </div>
            {/*Nothing*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.ico === "AiOutlineCloseCircle"}
                    value="AiOutlineCloseCircle"
                    onChange={handleChange}
                    name="ico"
                    id="optionsRadios4"
                  />
                  <GrClose />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>
            <strong>Quienes participan del saludo</strong>
          </label>
          <input
            type="text"
            disabled={obituary.obitSelected === "m5"}
            className="form-control"
            name="quienes"
            value={obituary.quienes}
            onChange={handleChange}
          ></input>
        </div>

        <div className="form-group">
          <label>
            <strong>Opciones de mensajes</strong>
          </label>
          <div className="row">
            {/*obit1*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.obitSelected === "m1"}
                    value={"m1"}
                    onChange={handleChange}
                    name="obitSelected"
                    id="optionsRadios1"
                  />
                  Opción A
                </label>
              </div>
            </div>

            {/*obit2*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.obitSelected === "m2"}
                    value="m2"
                    onChange={handleChange}
                    name="obitSelected"
                    id="optionsRadios2"
                  />
                  Opción B
                </label>
              </div>
            </div>
            {/*onit3*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.obitSelected === "m3"}
                    value="m3"
                    onChange={handleChange}
                    name="obitSelected"
                    id="optionsRadios3"
                  />
                  Opción C
                </label>
              </div>
            </div>
            {/*onit4*/}
            <div className="p-2">
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={obituary.obitSelected === "m4"}
                    value="m4"
                    onChange={handleChange}
                    name="obitSelected"
                    id="optionsRadios4"
                  />
                  Opción D
                </label>
              </div>
            </div>
            {/*onit4*/}
            {tweens && (
              <div className="p-2">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      checked={obituary.obitSelected === "m5"}
                      value="m5"
                      onChange={handleChange}
                      name="obitSelected"
                      id="optionsRadios5"
                    />
                    Crear nuevo
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Obit
      <div className="form-group">
        <label>Mensaje</label>
        <textarea
          type="text"
          className="form-control"
          name="obit"
          value={obituary.obit}
          onChange={handleChange}
          rows="5"
        ></textarea>
      </div> */}
        {tweens && obituary.obitSelected === "m5" && (
          <div>
            <ObituaryEdit
              registro={obituary}
              setObituary={setObituary}
              preview="true"
            ></ObituaryEdit>
          </div>
        )}
        {obituary.obitSelected != "m5" && (
          <div>
            <Obituary registro={obituary} preview="true"></Obituary>
          </div>
        )}
        <div className="row d-flex justify-content-between">
          <div className="col-md-3">
            <button
              onClick={goBack}
              className="btn btn-secondary btn-block mb-2 p-2"
            >
              Cancelar
            </button>
          </div>

          <div className="col-md-3">
            {!user && (
              <button
                type="button"
                className="btn btn-primary btn-block mb-2 p-2"
                onClick={saveCondolenciaNonUser}
              >
                Siguiente
              </button>
            )}
            {user && business && (
              <button
                type="button"
                className="btn btn-primary btn-block mb-2 p-2"
                onClick={saveCondolenciaBusiness}
              >
                Siguiente
              </button>
            )}
          </div>
        </div>
        {alert && (
          <div className="alert alert-dismissible alert-danger">
            <strong>upss!</strong> {alert}
          </div>
        )}
        {/* <a className="btn btn-primary" href="https://mpago.la/12my2mi">Link de pago</a> */}
      </div>
    </div>
  );
};

export default ObituaryForm;
