import React, { useState, useEffect } from "react";
import "firebase/firestore";
import { useFirebaseApp } from "reactfire";
import moment from "moment/min/moment-with-locales";

const Se_SanJosePdu = (props) => {
  // STATUS: Array de servicios
  const [deceased, setDeceased] = useState([]);
  // Set foramto de fecha y hora
  moment.locale("es");
  // Variable para el acceso a datastore
  const db = useFirebaseApp().firestore();

  const isTodayService = (servicio) => {
    const fecha = moment(Date.now()).format("DDMMYYYY");
    const fechafallecimiento = moment(servicio.fehcafallecimiento).format(
      "DDMMYYYY"
    );
    const fechainicio = moment(servicio.fechainicio).format("DDMMYYYY");
    const fechafinaliza = moment(servicio.fechafinaliza).format("DDMMYYYY");
    const fechasepelio = moment(servicio.fechasepelio).format("DDMMYYYY");
    const fecharegistro = moment(servicio.fecharegistro.toDate()).format(
      "DDMMYYYY"
    );

    const result =
      fecha === fecharegistro ||
      fecha === fechafallecimiento ||
      fecha === fechainicio ||
      fecha === fechafinaliza ||
      fecha === fechasepelio;
    return result;
  };

  /*************************************
   * Funcion apra la obtencion de datos de Servicios
   */
  const getDeceased = () => {
    db.collection("deceased")
      .orderBy("fecharegistro", "desc")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (
            !doc.data().deleted &&
            doc.data().empresaid === "0"
            //  &&
            // isTodayService(doc.data())
          ) {
            docs.push({ ...doc.data(), id: doc.id });
          }
        });
        setDeceased(docs);
        setMax(docs.length);
      });
  };

  /******************
   * Seteamos el titulo para el banner
   */
  useEffect(() => {
    const setmain = props.setHeader;
    setmain(false);
  }, []);

  /*****************
   * Obtener el listado de servicios
   */
  useEffect(() => {
    getDeceased();
  }, []);

  const [seconds, setSeconds] = useState(0);
  const [max, setMax] = useState(0);

  /*********************************************
   * Obtiene el listado de servicios y generea el banner Dinamico de servicios
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => (seconds < max - 1 ? seconds + 1 : 0));
    }, 5000);
    return () => clearInterval(interval);
  }, [max]);

  return (
    <div>
      <div class="in-center navbar-expand-lg navbar-dark bg-primary">
        <div className="footer-copyright text-center text-white navbar-dark bg-primary pt-3">
          <h3> {deceased[0] && deceased[0].empresa.toUpperCase()}</h3>
        </div>
      </div>
      {deceased.length > 0 && (
        <div className="">
          <div className="abs-center d-flex justify-content-center">
            <div>
              <div className="bubble display-1">
                {" "}
                {deceased[seconds].nombre.toUpperCase()}
              </div>
              <div className="d-flex justify-content-center display-4">
                {moment(deceased[seconds].fehcafallecimiento).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </div>
          </div>
          <div className="abs2-center d-flex justify-content-center">
            <div>
              <div className=" ">
                <div>
                  <div className="bubble d-flex justify-content-center display-3">
                    {deceased[seconds].sala}{" "}
                  </div>
                </div>

                <div className="">
                  <div className="d-flex justify-content-center display-5">
                    <i> {deceased[seconds] && deceased[seconds].empresa}</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="in-center navbar-expand-lg navbar-dark bg-primary">
        <div className="footer-copyright text-center text-white navbar-dark bg-primary pt-2">
          © 2020 PoweredBy EnMemoria.com.uy
          <div className="text-center text-white navbar-dark bg-primary">
            <a className="link" href="https://enmemoria.com.uy">
              https://enmemoria.com.uy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Se_SanJosePdu;
