import React, { useEffect, useState } from "react";
import { useFirebaseApp, useUser } from "reactfire";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { _obituaryPrice } from "../../EnMemoriaConfig";

const Contact = (props) => {
  let history = useHistory();
  const goBack = () => history.goBack();

  const [message, setMessage] = useState({});
  const [aviso, setAviso] = useState("");

  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Contáctanos");
  });

  const db = useFirebaseApp().firestore();
  const firebase = useFirebaseApp();
  const user = useUser();

  const sendMessage = async () => {
    if (
      message.nombre &&
      (message.email || message.telefono) &&
      message.mensaje
    ) {
      const now = Date.now();
      const created = firebase.firestore.Timestamp.fromDate(new Date(now));
      var userUid = "";
      if (user) {
        userUid = user.uid;
      }
      const result = await db.collection("message").add({
        ...message,
        fecharegistro: created,
        uid: userUid,
      });
      if (result.id) {
        toast(
          "El mensaje se ha enviado correctamente, en la brevedad posible nos comunicaremos. Gracias",
          {
            type: "success",
          }
        );
      } else if (!result.id) {
        toast(
          "No hemos podido enviar tu mensaje, por favor intentalo más tarde",
          {
            type: "alert",
          }
        );
      }
      goBack();
    } else {
      setAviso(
        "Es necesario ingresar nombre, datos de contacto y mensaje. Gracias"
      );
    }
  };

  const handleChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  return (
    <div className="card bg-light mb-3">
      <div className="card-header">
        {" "}
        <h4>
          <strong>EnMemoria.com.uy</strong>
        </h4>
      </div>
      <div className="card-body">
        {/* <p className="card-text">Por favor comunícate con nosotros.</p> */}
        <p>
          Avisos fúnebres de Uruguay, publicación de obiturarios. Envíanos un
          email a info@enmemroia.com.uy o si lo prefieres completa el siguiente
          formulario y nos comunicaremos a la brevedad.
        </p>
        {/* <p className="card-text">contactanos.</p> */}
        <form className="">
          <div className="form-group">
            <label>
              <h4>
                <strong>Formulario de contacto</strong>
              </h4>
            </label>
          </div>

          <div className="form-group">
            <label>
              <strong>Nombre</strong>
            </label>
            <input
              type="text"
              className="form-control"
              name="nombre"
              value={message.nombre}
              onChange={handleChange}
              onBlur={() => setAviso("")}
              required
            ></input>
          </div>

          <div className="form-group">
            <label>
              <strong>Email</strong>
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={message.email}
              onChange={handleChange}
              onBlur={() => setAviso("")}
            ></input>
          </div>

          <div className="form-group">
            <label>
              <strong>Teléfono</strong>
            </label>
            <input
              type="text"
              className="form-control"
              name="telefono"
              value={message.telefono}
              onChange={handleChange}
              onBlur={() => setAviso("")}
              required
            ></input>
          </div>
          <div className="form-group">
            <label>
              <strong>Mensaje</strong>
            </label>
            <textarea
              type="text"
              className="form-control"
              id="textObit"
              name="mensaje"
              value={message.mensaje}
              onChange={handleChange}
              onBlur={() => setAviso("")}
              required
              rows="5"
            ></textarea>
          </div>
          <div className="row d-flex justify-content-end">
            {aviso && (
              <div class="alert alert-dismissible alert-danger">
                <strong>upss!</strong> {aviso}
              </div>
            )}
            <div className="col-md-3">
              <button
                type="button"
                className="btn btn-primary btn-block mb-2 p-2"
                onClick={sendMessage}
              >
                Enviar Mensaje
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
