import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PagNumber = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumber.push(i);
  }
  return (
    <Pagination aria-label="Page navigation">
      <PaginationItem>
        <PaginationLink first onClick={() => paginate(1)} href="#" />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          previous
          onClick={() =>
            paginate(currentPage > 1 ? currentPage - 1 : currentPage)
          }
          href="#"
        />
      </PaginationItem>
      {pageNumber.map((num) => (
        <PaginationItem active={num === currentPage} key={num}>
          <PaginationLink onClick={() => paginate(num)} href="#">
            {num}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem>
        <PaginationLink
          next
          onClick={() =>
            paginate(
              currentPage < Math.ceil(totalPosts / postsPerPage)
                ? currentPage + 1
                : currentPage
            )
          }
          href="#"
        />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink
          last
          onClick={() => paginate(Math.ceil(totalPosts / postsPerPage))}
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

export default PagNumber;
