import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./firebase";
import "./index.css";
import App from "./App";
import { FirebaseAppProvider } from "reactfire";
import firebaseConfig from "./firebase-config";
import * as serviceWorker from "./serviceWorker";
import "bootswatch/dist/sandstone/bootstrap.min.css";

// TODO: Nota: Reemplaza ^ [theme] ^ (ejemplos: darkly, slate, cosmo, spacelab y superhero. Consulta https://bootswatch.com para conocer los nombres de los temas actuales).
ReactDOM.render(
  // <React.StrictMode>
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Suspense fallback="Aguarde estamos conectando la app ...">
      <App />
    </Suspense>
  </FirebaseAppProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
