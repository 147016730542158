import React, { useState, useEffect } from "react";
import { FaCross, FaStarOfDavid, FaRibbon } from "react-icons/fa";
import { GiShutRose } from "react-icons/gi";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { useFirebaseApp } from "reactfire";
import { useHistory } from "react-router-dom";
import moment from "moment";
//import "moment/locale/es";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
const Obituary = (props) => {
  const history = useHistory();
  const goObituaryForm = () => {
    history.push("/formulario/condolencia/" + deceased.id);
  };
  moment.locale("es");
  const [modal, setModal] = useState({ modal: false, isOpen: true });
  const [deceased, setDeceased] = useState({});
  const registro = props.registro;

  /******
   * Metodo que captura las acciones del modal y lo apaga
   */
  const toggleModal = () => {
    if (!props.preview) {
      setModal({
        modal: !modal.modal,
        isOpen: false,
      });
    }
  };

  useEffect(() => {
    loadDeseaced();
  }, []);

  const db = useFirebaseApp().firestore();
  const loadDeseaced = async () => {
    try {
      const doc = await db
        .collection("deceased")
        .doc(registro.deceasedId)
        .get();
      setDeceased({ ...doc.data(), id: registro.deceasedId });
    } catch (error) {}
  };

  const handleClick = async () => {
    // if (deseaced) {
    goObituaryForm();
    //}
  };

  const url = "https://enmemoria.com.uy";
  const mensajeRedes =
    " Informamos el fallecimiento de " +
    deceased.nombre +
    ", el día " +
    moment(deceased.fehcafallecimiento).format("LL") +
    " - " +
    deceased.empresa;

  return (
    <div>
      <Modal isOpen={modal.modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}> {deceased.nombre}</ModalHeader>
        <ModalBody>
          <div className="p-4">
            {" "}
            <p>
              <label>
                <strong>
                  Fallecio el día{" "}
                  {moment(deceased.fehcafallecimiento).format("LL")}
                </strong>
              </label>
            </p>
            <p>
              {deceased.empresa && (
                <label>
                  <strong>Empresa:</strong> {deceased.empresa}
                </label>
              )}
              {deceased.sala && (
                <label>
                  <strong> Sala: </strong> {deceased.sala}
                </label>
              )}
            </p>
            {deceased && deceased.fechainicio && (
              <p>
                <label>
                  <strong>Velatorio:</strong>{" "}
                  {moment(deceased.fechainicio).format("LL")}
                  {" a las "}
                  {deceased.horainicio}
                  {" al "}
                  {moment(deceased.fechafinaliza).format("LL")}
                  {" a las "} {deceased.horafinaliza}
                </label>
              </p>
            )}
            {deceased && deceased.fechasepelio && (
              <p>
                <label>
                  <strong>Sepelio: </strong>
                  {moment(deceased.fechasepelio).format("LL")}
                  {" a las "}
                  {deceased.horasepelio}
                  {" - "}
                  <strong>{deceased.cementerio}</strong>
                </label>
              </p>
            )}
            {deceased && deceased.nota && (
              <p>
                <label>
                  {" "}
                  <strong>{deceased.nota}</strong>
                </label>
              </p>
            )}
          </div>
          {!deceased.deleted && (
            <button
              type="button"
              className="btn btn-success"
              onClick={handleClick}
            >
              {" "}
              Enviar saludo
            </button>
          )}
        </ModalBody>
        <ModalFooter>
          <p>
            <WhatsappShareButton url={url} title={mensajeRedes}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>{" "}
            <TelegramShareButton url={url} title={mensajeRedes}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>{" "}
            <EmailShareButton url={url} title={mensajeRedes}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>{" "}
            <FacebookMessengerShareButton url={url} title={mensajeRedes}>
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
          </p>
        </ModalFooter>
      </Modal>
      <div className="card border-dark mb-3 myButton" onClick={toggleModal}>
        <div className="card-header">
          <h4>
            <div className="d-flex bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                {props.registro.ico === "FaCross" && <FaCross />}
                {props.registro.ico === "FaStarOfDavid" && <FaStarOfDavid />}
                {props.registro.ico === "GiShutRose" && <GiShutRose />}
                {props.registro.ico === "FaRibbon" && <FaRibbon />}
              </div>
              <div className="p-2 bd-highlight"> {props.registro.nombre}</div>
              {!props.preview && (
                <div className="btn ml-auto p-2 bd-highlight">
                  <i className="material-icons" onClick={toggleModal}>
                    info_outline
                  </i>
                </div>
              )}
            </div>
            {/* <i className="material-icons">add</i> */}{" "}
          </h4>
        </div>

        <div className="card-body">
          <h4 className="card-title">{props.registro.obit}</h4>

          <p className="card-text">{props.registro.empresa}</p>
          {/* <button type="button" className="btn btn-outline-secondary">Enviar saludo</button> */}
          {/* <i className="material-icons" onClick={()=>{handleClick()}}>mail_outline</i>
        <i className="material-icons" onClick={()=>{handleClick()}}>info</i> */}
        </div>
      </div>
    </div>
  );
};

export default Obituary;
