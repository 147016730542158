import React, { useContext, useEffect, useState } from "react";

import "firebase/firestore";
import { useFirebaseApp, useUser } from "reactfire";

import moment from "moment/min/moment-with-locales";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineCheck } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ObituaryCard from "./ObituaryCard";
import { FaCross, FaStarOfDavid, FaRibbon } from "react-icons/fa";
import { GiShutRose } from "react-icons/gi";
import { GrClose, GrSearchAdvanced } from "react-icons/gr";
import PagNumber from "../pagination/pagination";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { UserContext } from "../../store/UserContext";

const ObituaryPosts = (props) => {
  const { userData } = useContext(UserContext);

  const user = useUser();
  moment.globalLocale = "es";
  // Set foramto de fecha y hora
  moment.locale("es");
  const db = useFirebaseApp().firestore();

  /****************************************************
   * Constantes para la paginacion
   */
  const postsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [tweens, setTweens] = useState(userData && userData.rol === "admin");

  useEffect(() => {
    if (
      userData &&
      userData._userData &&
      userData._userData.rol === "admin"
      // (user.email === "hugo.rivero@montevideo.com.uy" ||
      //   user.email === "vrrm@adinet.com.uy")
    ) {
      setTweens(true);
    }
  });
  /*PageNumber*/
  const paginate = (pageNumber) => {
    const iolp = pageNumber * postsPerPage;
    const iofp = iolp - postsPerPage;
    const cp = list.slice(iofp, iolp);
    setCurrentPosts(cp);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    obtenerPosts();
  }, [list]);

  /**
   * Metodo que obteiene la lista en la plataforma
   */
  const obtenerPosts = async () => {
    //SI esta logueado
    if (user) {
      if (list) {
        const iolp = 1 * postsPerPage;
        const iofp = iolp - postsPerPage;
        const cp = list.slice(iofp, iolp);

        //setList(list);
        setCurrentPosts(cp);
      } else {
        goHome();
      }
    }
  };

  const getData = async () => {
    if (user) {
      db.collection("obituary")
        .orderBy("fecharegistro", "desc")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            if (
              (userData &&
                userData._userData &&
                userData._userData.rol === "admin") ||
              (doc.data().uid === user.uid && !doc.data().deleted)
            ) {
              docs.push({ ...doc.data(), id: doc.id });
            }
          });
          setOriginalList(docs);
          setList(docs);
        });
    }
  };

  const deleteObituary = (id) => {
    if (user) {
      confirmAlert({
        title: "Confirmar eliminación",
        message: "¿Desea eliminar la publicación?.",
        buttons: [
          {
            label: "Si",
            onClick: () =>
              db.collection("obituary").doc(id).update({ deleted: true }),
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  const editObituary = (servicio) => {
    setServicioActual(servicio);
    toggleInfoModal();
  };

  const addObituary = (servicio) => {
    if (user) {
      history.push("/formulario/condolencia/" + servicio.deceasedId);
    }
  };

  useEffect(() => {
    getData();
  }, [userData]);

  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Obituarios");
  });

  const history = useHistory();
  const goHome = () => history.push("/");

  /*********************
   * Modal Innfo Obituario
   */
  const [modalInfo, setModalInfo] = useState(false);
  const [servicioActual, setServicioActual] = useState("");
  const toggleInfoModal = () => {
    setModalInfo(!modalInfo);
  };
  const handleChangeServicioActual = (e) => {
    setServicioActual({ ...servicioActual, obit: e.target.value });
  };
  const handleChangeNombre = (e) => {
    setServicioActual({ ...servicioActual, nombre: e.target.value });
  };
  const handleChange = (e) => {
    setServicioActual({ ...servicioActual, ico: e.target.value });
  };

  const updateServicioActual = () => {
    toggleInfoModal();
    db.collection("obituary").doc(servicioActual.id).update(servicioActual);
  };

  /***********************************************************
   * Variables para el manejo del Input SEARCH
   * variable de estado y handleChange
   */

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    //var post = totalDatos.slice(0, totalDatos.length);
    setList(filterItemsObituary(originalList, e.target.value));
  };

  const filterItemsObituary = (p, query) => {
    if (query) {
      return p.filter((el) => {
        return (
          moment(el.fecharegistro).format("ll").indexOf(query) > -1 ||
          el.nombre.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.obit.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.sala.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.fechasepelio.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    } else {
      return p;
    }
  };

  return (
    <div>
      {!user && goHome()}
      {user && (
        <div>
          <Modal isOpen={modalInfo} toggle={toggleInfoModal}>
            <ModalHeader toggle={toggleInfoModal}>
              <strong className="p-3">Edición de obituario</strong>
            </ModalHeader>
            <ModalBody>
              <strong className="p-3">Nombre: </strong>
              <input
                type="text"
                className="form-control"
                name="nombre"
                value={servicioActual.nombre}
                onChange={handleChangeNombre}
              ></input>
              <strong className="p-3">Icono: </strong>
              <div className="form-group">
                <div className="form-group">
                  <div className="row p-2">
                    {/*faCross*/}
                    <div className="p-2">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={servicioActual.ico === "FaCross"}
                            value="FaCross"
                            onChange={handleChange}
                            name="ico"
                            id="optionsRadios1"
                          />
                          <h3>
                            <FaCross />
                          </h3>
                        </label>
                      </div>
                    </div>

                    {/*FaStarOfDavid*/}
                    <div className="p-2">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={servicioActual.ico === "FaStarOfDavid"}
                            value="FaStarOfDavid"
                            onChange={handleChange}
                            name="ico"
                            id="optionsRadios2"
                          />
                          <h3>
                            <FaStarOfDavid />
                          </h3>
                        </label>
                      </div>
                    </div>
                    {/*faRibbon*/}
                    <div className="p-2 ">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={servicioActual.ico === "FaRibbon"}
                            value="FaRibbon"
                            onChange={handleChange}
                            name="ico"
                            id="optionsRadios5"
                          />
                          <h3>
                            <FaRibbon />
                          </h3>
                        </label>
                      </div>
                    </div>

                    {/*GiShutRose*/}
                    <div className="p-2">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={servicioActual.ico === "GiShutRose"}
                            value="GiShutRose"
                            onChange={handleChange}
                            name="ico"
                            id="optionsRadios3"
                          />
                          <h3>
                            <GiShutRose />
                          </h3>
                        </label>
                      </div>
                    </div>
                    {/*Nothing*/}
                    <div className="p-2 ">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={
                              servicioActual.ico === "AiOutlineCloseCircle"
                            }
                            value="AiOutlineCloseCircle"
                            onChange={handleChange}
                            name="ico"
                            id="optionsRadios4"
                          />
                          <GrClose />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <strong className="p-3">Saludo: </strong>
                <textarea
                  type="text"
                  className="form-control"
                  name="obit"
                  value={servicioActual.obit}
                  onChange={handleChangeServicioActual}
                  rows="8"
                ></textarea>
              </div>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-secondary" onClick={toggleInfoModal}>
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                onClick={updateServicioActual}
              >
                Modificar
              </button>
            </ModalFooter>
          </Modal>
          {/* SEARCH BOX */}
          <div className="form-group">
            <input
              className="form-control "
              name="search"
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {/* OBITUARY LIST */}
          {/* DESKTOP */}
          <div className="d-none d-lg-block d-xl-block">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Registro</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Obituario</th>
                  {!tweens && <th scope="col">Sala</th>}
                  {tweens && <th scope="col">Publicado</th>}
                  {tweens && <th scope="col">Borrado</th>}
                  {tweens && <th scope="col">Empresa</th>}
                  {tweens && <th scope="col">By</th>}

                  <th className="myTd" scope="col">
                    Modificar
                  </th>
                  <th className="myTd" scope="col">
                    Borrar
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((servicio) => {
                  return (
                    <>
                      {servicio.user && (
                        <tr className="table-light" key={servicio.id}>
                          <td>
                            {moment(servicio.fecharegistro.toDate()).format(
                              "lll"
                            )}
                          </td>
                          <td>{servicio.nombre}</td>
                          <td>{servicio.obit}</td>
                          {!tweens && <td>{servicio.sala}</td>}
                          {tweens && servicio.paymentStatus && (
                            <td>{servicio.paymentStatus}</td>
                          )}
                          {tweens && !servicio.paymentStatus && (
                            <td>"sin info"</td>
                          )}

                          {tweens &&
                            (servicio.deleted ? <td>SI</td> : <td>NO</td>)}

                          {tweens && <td>{servicio.empresa}</td>}
                          {tweens && <td>{servicio.user || "Otro"}</td>}

                          <td className="myTd">
                            <h3>
                              <AiOutlineEdit
                                className="myButton"
                                onClick={() => editObituary(servicio)}
                              />
                            </h3>
                          </td>
                          <td className="myTd">
                            <h4>
                              <AiOutlineDelete
                                className="myButton"
                                onClick={() => deleteObituary(servicio.id)}
                              />
                            </h4>
                          </td>
                        </tr>
                      )}
                      {!servicio.user && (
                        <tr className="table-success" key={servicio.id}>
                          <td>
                            {moment(servicio.fecharegistro.toDate()).format(
                              "lll"
                            )}
                          </td>
                          <td>{servicio.nombre}</td>
                          <td>{servicio.obit}</td>
                          {!tweens && <td>{servicio.sala}</td>}
                          {tweens && servicio.paymentStatus && (
                            <td>{servicio.paymentStatus}</td>
                          )}
                          {tweens && !servicio.paymentStatus && (
                            <td>"sin info"</td>
                          )}
                          {tweens &&
                            (servicio.deleted ? <td>SI</td> : <td>NO</td>)}

                          {tweens && <td>{servicio.empresa}</td>}
                          {tweens && <td>{servicio.user || "Otro"}</td>}
                          {/* <td className="myTd">
                        <h3>
                          <AiOutlineFileAdd
                            className="myButton"
                            onClick={() => addObituary(servicio)}
                          />
                        </h3>
                      </td> */}
                          <td className="myTd">
                            <h3>
                              <AiOutlineEdit
                                className="myButton"
                                onClick={() => editObituary(servicio)}
                              />
                            </h3>
                          </td>
                          <td className="myTd">
                            <h4>
                              <AiOutlineDelete
                                className="myButton"
                                onClick={() => deleteObituary(servicio.id)}
                              />
                            </h4>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* SMALL DEVICE SCREEN */}
          <div className="form-group d-lg-none">
            {currentPosts.map((servicio) => {
              return (
                <ObituaryCard
                  key={servicio.id}
                  tweens={tweens}
                  servicio={servicio}
                  addObituary={addObituary}
                  editObituary={editObituary}
                  deleteObituary={deleteObituary}
                />
              );
            })}
          </div>
          {/*PAGINATION*/}
          <Row className="d-flex justify-content-center">
            <PagNumber
              postsPerPage={postsPerPage}
              totalPosts={list.length}
              paginate={paginate}
              currentPage={currentPage}
            ></PagNumber>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ObituaryPosts;
