import React, { useState, useEffect } from "react";
import "firebase/firestore";
import { useFirebaseApp } from "reactfire";
import Obituary from "./Obituary";
import ObituarySmall from "./ObituarySmall";
import moment from "moment/min/moment-with-locales";
import HideableText from "../autocomplete/HideableText";
import AutocompleteText from "../autocomplete/AutocompleteText";
import Autosuggest from "react-autosuggest";

const ObituaryMain = (props) => {
  // STATUS: Array de obituarios
  const [obituarys, setObituarys] = useState([]);
  // STATUS: Array de servicios
  const [deceased, setDeceased] = useState([]);
  const [deceasedTotales, setDeceasedTotales] = useState([]);

  // Set foramto de fecha y hora
  moment.locale("es");
  // Variable para el acceso a datastore
  const db = useFirebaseApp().firestore();

  const isCountDays = (fecha) => {
    const fechaNow = moment(Date.now());
    const fecharegistro = moment(fecha.toDate());

    const result = fechaNow.diff(fecharegistro, "days");
    // console.log("FECHAHOY:", fecha);
    // console.log("FECHAfa:", fechafallecimiento);
    // console.log("FECHAini:", fechainicio);
    // console.log("FECHAfina:", fechafinaliza);
    // console.log("FECHAsepe:", fechasepelio);
    // console.log("FECHAreg:", fecharegistro);
    // console.log("RESULTADO FECHAS: ", result);

    return result;
  };

  /*************************************
   * Funcion apra la obtencion de datos de Servicios
   */
  const getDeceased = () => {
    var deceasedToday = 0;
    const today = moment(new Date()).format("yyyy-mm-dd");
    db.collection("deceased")
      .orderBy("fecharegistro", "desc")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        const docsTotales = [];
        querySnapshot.forEach((doc) => {
          if (
            !doc.data().deleted &&
            isCountDays(doc.data().fecharegistro) < 15
          ) {
            //console.log("#Dias ", isCountDays(doc.data().fecharegistro));
            docs.push({ ...doc.data(), id: doc.id });
            if (isCountDays(doc.data().fecharegistro) < 2) {
              deceasedToday++;
            }
          }
          if (!doc.data().deleted) {
            docsTotales.push({ ...doc.data(), id: doc.id });
          }
        });
        setDeceased(docs);
        setDeceasedTotales(docsTotales);
        setMax(deceasedToday);
      });
  };

  /**********************************
   * funcion que obtiene los datos de los Obituarios
   */
  const getObituary = () => {
    db.collection("obituary")
      .orderBy("fecharegistro", "desc")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (
            doc.data().paymentStatus === "approved" &&
            !doc.data().deleted &&
            isCountDays(doc.data().fecharegistro) < 15
          ) {
            docs.push({ ...doc.data(), id: doc.id });
          }
        });
        setObituarys(docs);
      });
  };

  /******************
   * Seteamos el titulo para el banner
   */
  useEffect(() => {
    const setmain = props.handleMain;
    setmain("En Memoria");
  }, []);

  /******************
   * Obtener el listado de Obituarios
   */
  useEffect(() => {
    getObituary();
  }, []);

  /*****************
   * Obtener el listado de servicios
   */
  useEffect(() => {
    getDeceased();
  }, []);

  /***********************************************************
   * Variables para el manejo del Input SEARCH
   * variable de estado y handleChange
   */

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const [seconds, setSeconds] = useState(0);
  const [max, setMax] = useState(0);

  /*********************************************
   * Obtiene el listado de servicios y generea el banner Dinamico de servicios
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => (seconds < max - 1 ? seconds + 1 : 0));
    }, 3000);
    return () => clearInterval(interval);
  }, [max]);

  /**********************************************************************
   * SEARCH ENGINE
   */

  /******************************
   * funcion auxiliar -Normalizar: dado un string lo retorna sin tíldes
   /************************* */
  const noTilde = (s) => {
    if (s.normalize != undefined) {
      s = s.normalize("NFKD");
    }
    //quitamos el tilde, dieresis, etc de la forma NFKD
    return s.replace(/[\u0300-\u036F]/g, "");
  };

  /*********************************************
   * Funcion para el filtro de Obituarios segun el search text
   */
  const filterItemsObituary = () => {
    //Quitamos espacios a las palabras que queremos buscar
    const query = search.trim();
    if (query) {
      return obituarys.filter((el) => {
        var texto = el.nombre + " " + el.obit + " " + el.empresa + " ";
        //solo retornamos los elementos que contangan todas las palabras buscadas
        return query
          .split(" ")
          .every((palabra) =>
            noTilde(texto.toLocaleLowerCase()).includes(
              noTilde(palabra.toLowerCase())
            )
          );
      });
    } else {
      return obituarys;
    }
  };

  /*********************************************
   * Funcion para el filtro de servicios segun el search text
   */
  const filterItemsDeceasedSmall = () => {
    //Quitamos espacios a las palabras que queremos buscar
    const query = search.trim();
    if (query) {
      //filtramos la colleccion
      return deceasedTotales.filter((el) => {
        //de cada elemento seleccionamos los atributos que participaran en la búsqueda
        var texto =
          el.nombre +
          " " +
          el.deptocementerio +
          " " +
          el.empresa +
          " " +
          el.cementerio +
          " " +
          "Falleció el" +
          " " +
          moment(el.fehcafallecimiento).format("LL");
        //solo retornamos los elementos que contangan todas las palabras buscadas
        return query
          .split(" ")
          .every((palabra) =>
            noTilde(texto.toLocaleLowerCase()).includes(
              noTilde(palabra.toLowerCase())
            )
          );
      });
    }
    //filtramos la colleccion
    return deceased.filter((el) => {
      //de cada elemento seleccionamos los atributos que participaran en la búsqueda
      var texto =
        el.nombre +
        " " +
        el.deptocementerio +
        " " +
        el.empresa +
        " " +
        el.cementerio +
        " " +
        "Falleció el" +
        " " +
        moment(el.fehcafallecimiento).format("LL");
      //solo retornamos los elementos que contangan todas las palabras buscadas
      return query
        .split(" ")
        .every((palabra) =>
          noTilde(texto.toLocaleLowerCase()).includes(
            noTilde(palabra.toLowerCase())
          )
        );
    });
  };

  /****************************************
   * SUGGESTIONS
   */

  /****************************************
   *
   */

  return (
    <div>
      <div className="p-2">
        {/* SEARCH BOX */}
        <div className="form-group d-lg-none">
          <div className="">
            <input
              className="form-control "
              name="search"
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {/* NAME DINAMIC DISPLAY */}
          <div>
            {deceased.length > 0 && (
              <div className="justify-content-end">
                <blockquote className="blockquote text-rigth">
                  <p className="mb-0">{deceased[seconds].nombre}.</p>
                  <footer className="blockquote-footer">
                    {deceased[seconds].empresa}
                    {", "}
                    <cite title="Source Title">
                      {deceased[seconds].fehcafallecimiento &&
                        moment(deceased[seconds].fehcafallecimiento).format(
                          "LL"
                        )}
                    </cite>
                  </footer>
                </blockquote>
              </div>
            )}
          </div>
          {/* SEARCH BOX */}
          <div className="d-none d-lg-block d-xl-block">
            <div className="form-inline">
              <input
                className="form-control"
                name="search"
                type="text"
                placeholder="Buscar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="App-Component">
        <AutocompleteText items={deceased} />
      </div> */}
      {/* <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      /> */}
      {/* LISTADO DE OBITUARIOS SEGUN EL SEARCH */}
      {filterItemsObituary().map((obituario) => {
        return (
          <Obituary
            key={obituario.id}
            registro={obituario}
            data={deceased}
          ></Obituary>
        );
      })}
      {/* LISTADO DE SERVICIOS SEGUN EL SEARCH */}
      {filterItemsDeceasedSmall().map((deceased) => {
        return (
          <ObituarySmall
            id={deceased.id}
            nombre={deceased.nombre}
            empresa={deceased.empresa}
            key={deceased.id}
            deceased={deceased}
          ></ObituarySmall>
        );
      })}
      {/* DATA-DIV */}
      <div className="card mb-3">
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-md-9">
              <strong>AVISOS FÚNEBRES</strong>
              <div className="m-2">
                <div>
                  Publicación de obituarios, florerías, traslados, impresiones,
                  clasificados y más
                </div>
                <div className="myWhite">
                  Avisos funebres, envío de flores, traslados, diario hoy avisos
                  fúnebres previsión avisos fúnebres de hoy, clasificados
                  obituarios de hoy, obituarios del día. Búsquedas.
                </div>
              </div>
              <div className="m-2"></div>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-end">
                <a
                  className="btn btn-success"
                  href="https://enmemoria.com.uy/servicios"
                >
                  datos útiles
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObituaryMain;
