import React, { useState, useEffect, useContext } from "react";
// import { db } from "../../firebase";
import { useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import { useDatabase, useFirebaseApp, useUser } from "reactfire";
import { UserContext } from "../../store/UserContext";
import { ToastContainer } from "react-toastify";

const ObituaryCompleteForm = (props) => {
  var { userData } = useContext(UserContext);
  const [rooms, setRooms] = useState([]);
  const [necropolis, setNecropolis] = useState([]);
  const [deptoNecropolis, setDeptoNecropolis] = useState("Paysandú");
  const [empresas, setEmpresas] = useState([]);
  const history = useHistory();
  const goHome = () => history.push("/");
  const goBack = () => history.goBack();

  const deptos = [
    "Artigas",
    "Canelones",
    "Cerro Largo",
    "Colonia",
    "Durazno",
    "Flores",
    "Florida",
    "Lavalleja",
    "Maldonado",
    "Montevideo",
    "Paysandú",
    "Río Negro",
    "Rivera",
    "Rocha",
    "Salto",
    "San José",
    "Soriano",
    "Tacuarembó",
    "Treinta y Tres",
  ];

  const user = useUser();
  const mainTitle = { title: "Formulario Servicio", smview: true };

  const db = useFirebaseApp().firestore();
  const [obituary, setObituary] = useState({
    nombre: "",
    fehcafallecimiento: "",
    deleted: false,
    empresa: "",
    sala: "Sin especificar",
    fechainicio: "",
    horainicio: "",
    fechafinaliza: "",
    horafinaliza: "",
    fechasepelio: "",
    horasepelio: "",
    cementerio: "",
    deptocementerio: "Paysandú",
    obit: "",
    fecharegistro: firebase.firestore.Timestamp.fromDate(
      new Date(Date.now())
    ).toDate(),
  });
  const [message, setMessage] = useState("");
  useEffect(() => {
    const setmain = props.handleMain;
    setmain(mainTitle.title, mainTitle.smview);
  });

  const getRooms = () => {
    db.collection("rooms")
      .where("empresa", "==", userData._userData.empresa)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (!doc.data().deleted) {
            docs.push({ ...doc.data(), id: doc.id });
          }
        });
        setRooms(docs);
        setObituary({ ...obituary, empresa: userData._businessData.nombre });
        if (
          userData &&
          userData._businessData &&
          deptos.find((element) => element === userData._businessData.ciudad)
        ) {
          setDeptoNecropolis(userData._businessData.ciudad);
        }

        console.log("CIUDAD: ", userData._businessData.ciudad);
      });
  };

  const getNecropolis = () => {
    db.collection("necropolis").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        if (!doc.data().deleted) {
          docs.push({ ...doc.data(), id: doc.id });
        }
      });
      setNecropolis(docs);
    });
  };

  useEffect(() => {
    if (
      userData &&
      userData._userData &&
      userData._userData.rol === "business"
    ) {
      getRooms();
    }
    getNecropolis();
  }, [userData]);

  const handleChangeDeptoCementerio = (e) => {
    setObituary({ ...obituary, deptocementerio: e.target.value });
    setDeptoNecropolis(e.target.value);
  };

  const handleChange = (e) => {
    setMessage("");

    if (e.target.name === "nombre") {
      const nameUpper = e.target.value.toUpperCase();
      setObituary({ ...obituary, [e.target.name]: nameUpper });
    } else {
      setObituary({ ...obituary, [e.target.name]: e.target.value });
    }
  };

  const saveObituary = () => {
    const now = Date.now();
    const created = firebase.firestore.Timestamp.fromDate(
      new Date(now)
    ).toDate();
    const data = {
      ...obituary,
      fecharegistro: created,
      uid: user.uid,
      userid: userData._userData.id,
      empresaid: userData._userData.empresa,
      username: userData._userData.nombre,
    };

    setObituary(data);
    db.collection("deceased")
      .doc()
      .set(data)
      .then(() => {
        goBack();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setBtnEnable(true);
      });
  };

  const [btnEnable, setBtnEnable] = useState(true);
  const handleSubmite = (e) => {
    e.preventDefault();

    if (obituary.nombre) {
      setBtnEnable(false);
      saveObituary();
    } else {
      setMessage("Debe indicar un nombre.");
    }
  };

  return (
    <React.Fragment>
      {!user && goHome()}
      {user && (
        <div className="card card-body">
          {/* Nombre  */}
          <label>
            <strong>Persona</strong>
          </label>
          <div className="card form-group bg-light p-4">
            <div className="form-group">
              <label htmlFor="inputNombre">
                <strong>Nombre</strong>
              </label>
              <input
                type="text"
                className="form-control"
                id="inputNombre"
                name="nombre"
                value={obituary.nombre}
                onChange={handleChange}
                onBlur={() => setMessage("")}
              ></input>
            </div>
            <div className="form-group">
              <label htmlFor="inputFechafallecimiento">
                <strong>Fecha deceso</strong>
              </label>
              <input
                type="date"
                className="form-control"
                id="inputFechafallecimiento"
                name="fehcafallecimiento"
                value={obituary.fehcafallecimiento}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          {/* Empresa funebre */}

          {/* Sala */}
          <label>
            <strong>Velatorío</strong>
          </label>
          <div className="card form-group bg-light p-4">
            <div className="row">
              <div className="col-sm-12">
                <label htmlFor="selectEmpresa">Empresa</label>
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "business" && (
                    <input
                      className="form-control"
                      id="selectEmpresa"
                      name="empresa"
                      value={obituary.empresa}
                    ></input>
                  )}
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "admin" && (
                    <input
                      className="form-control"
                      id="selectEmpresa"
                      name="empresa"
                      value={obituary.empresa}
                      onChange={handleChange}
                    ></input>
                  )}
              </div>

              <div className="col-sm-6">
                <label className=" col-form-label" htmlFor="selectSala">
                  Sala
                </label>
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "business" && (
                    <select
                      className="form-control"
                      id="selectSala"
                      name="sala"
                      value={obituary.sala}
                      onChange={handleChange}
                    >
                      <option key={-2} value={"Sin especificar"}>
                        {"Sin especificar"}
                      </option>
                      {rooms &&
                        rooms.map((option) => {
                          const sala =
                            option.sala +
                            " - " +
                            option.direccion +
                            " - " +
                            option.ciudad;
                          return (
                            <option key={option.id} value={sala}>
                              {sala}
                            </option>
                          );
                        })}
                      <option key={-3} value={"No se realiza velatorio"}>
                        {"No se realiza velatorio"}
                      </option>
                    </select>
                  )}
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "admin" && (
                    <input
                      className="form-control"
                      id="selectSala"
                      name="sala"
                      value={obituary.sala}
                      onChange={handleChange}
                    ></input>
                  )}
              </div>

              <div className="col-sm-3">
                <label className=" col-form-label" htmlFor="inputFechainicio">
                  Fecha comienzo
                </label>
                <input
                  type="date"
                  className="form-control "
                  id="inputFechainicio"
                  name="fechainicio"
                  onChange={handleChange}
                  value={obituary.fechainicio}
                ></input>
              </div>

              <div className="col-sm-3">
                <label className=" col-form-label" htmlFor="inputHorainicio">
                  Hora comienzo
                </label>
                <input
                  type="time"
                  className="form-control "
                  id="inputHorainicio"
                  name="horainicio"
                  onChange={handleChange}
                  value={obituary.horainicio}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6" />
              <div className="col-sm-3">
                <label className=" col-form-label" htmlFor="inputFechafinaliza">
                  Fecha finalización
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="inputFechafinaliza"
                  name="fechafinaliza"
                  onChange={handleChange}
                  value={obituary.fechafinaliza}
                ></input>
              </div>

              <div className="col-sm-3">
                <label className=" col-form-label" htmlFor="inputHorafinaliza">
                  Hora finalización
                </label>
                <input
                  type="time"
                  className="form-control "
                  id="inputHorafinaliza"
                  name="horafinaliza"
                  onChange={handleChange}
                  value={obituary.horafinaliza}
                ></input>
              </div>
            </div>
          </div>
          {/* Sepelio */}
          <div className="form-group">
            <label htmlFor="exampleSelect2">
              <strong>Sepelio</strong>
            </label>
            <div className="card form-group bg-light p-4">
              <div className="row">
                <div className="col-sm-12">
                  <label
                    className="col-form-label"
                    htmlFor="selectDeptoCementerio"
                  >
                    Departamento
                  </label>
                  <select
                    className="form-control"
                    id="selectDeptoCementerio"
                    name="deptoCementerio"
                    value={deptoNecropolis}
                    onChange={handleChangeDeptoCementerio}
                  >
                    {deptos.map((depto) => {
                      return <option>{depto}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <label className="col-form-label" htmlFor="selectCementerio">
                    Cementerio
                  </label>
                  <select
                    className="form-control"
                    id="selectCementerio"
                    name="cementerio"
                    value={obituary.cementerio}
                    onChange={handleChange}
                  >
                    <option>Sin especificar</option>
                    {necropolis &&
                      necropolis.length > 0 &&
                      necropolis.map((necro) => {
                        if (deptoNecropolis === necro.departamento) {
                          return (
                            <option>
                              {necro.departamento} - {necro.name}
                            </option>
                          );
                        }
                      })}
                    <option>Cremación</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <label className="col-form-label" htmlFor="inputFechasepelio">
                    Fecha
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputFechasepelio"
                    name="fechasepelio"
                    value={obituary.fechasepelio}
                    onChange={handleChange}
                  ></input>
                </div>

                <div className="col-sm-3">
                  <label className=" col-form-label" htmlFor="inputHorasepelio">
                    Hora
                  </label>
                  <input
                    type="time"
                    className="form-control "
                    id="inputHorasepelio"
                    name="horasepelio"
                    onChange={handleChange}
                    value={obituary.horasepelio}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          {/* NOTA  */}
          <label>
            <strong>Nota</strong>
          </label>
          <div className="card form-group bg-light p-4">
            <div className="form-group">
              <label htmlFor="inputNota">
                <strong>Detalle</strong>
              </label>
              <input
                type="text"
                className="form-control"
                id="inputNota"
                name="nota"
                value={obituary.nota}
                onChange={handleChange}
                onBlur={() => setMessage("")}
              ></input>
            </div>
          </div>

          {/* BOTONES */}
          <div className="row d-flex justify-content-between">
            <div className="col-md-3">
              <button
                onClick={goHome}
                className="btn btn-secondary btn-block mb-2 p-2"
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-3">
              {btnEnable && (
                <button
                  type="button"
                  className="btn btn-primary btn-block mb-2 p-2"
                  onClick={handleSubmite}
                >
                  Guardar
                </button>
              )}
              {!btnEnable && (
                <button
                  type="button"
                  className="btn btn-primary disabled btn-block mb-2 p-2"
                >
                  Guardar
                </button>
              )}
            </div>
          </div>
          {message && (
            <div class="alert alert-dismissible alert-danger">
              <strong>upss!</strong> {message}
            </div>
          )}
          {/* <a className="btn btn-primary" href="https://mpago.la/12my2mi">Link de pago</a> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default ObituaryCompleteForm;
