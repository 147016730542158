import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
const PayPal = (props) => {
  const paypal = useRef();
  const history = useHistory();
  const goHome = () => history.push("/");
  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "pay",
        },
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Obitario - EnMemoria.com.uy",
                //reference_id: props.obituary.quiensaluda,
                amount: {
                  currency_code: "USD",
                  value: 5.89,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (order.status === "COMPLETED") {
            props.saveCondolenciaPayPal();
          }
        },
        onError: (err) => {
          console.log("ERROR: ", err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
};

export default PayPal;
