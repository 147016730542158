import React, { useEffect } from "react";
import PublicidadCard from "./PublicidadCard";
import PublicidadImageCard from "./PublicidadImageCard";

const Services = (props) => {
  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Datos útiles");
  });

  return (
    <div>
      <PublicidadCard
        comercio={{
          rubros:
            "Servicios Funerarios - Servicio de Traslado - Servicio de Cremación",
          nombre: "Cochería y Previsora Famililar San José",
          datos:
            "Dirección José Pedro Varela 749 Paysandú, Uruguay. Teléfono 4722 2146 - Email icelsa1@adinet.com.uy",
          horarios: "24 horas",
          web: "https://www.cocheriasanjose.com.uy/",
          webdescripcion: "https://www.cocheriasanjose.com.uy/",
        }}
      ></PublicidadCard>
      {/* <PublicidadImageCard
        comercio={{
          rubros:
            "Servicios Funerarios - Servicio de Traslado - Servicio de Cremación",
          nombre: "Cochería y Previsora Famililar San José",
          datos: "Dirección José Pedro Varela 749 Paysandú, Uruguay",
          telefono: "Teléfono 4722 2146 ",
          email: "Email icelsa1@adinet.com.uy",
          horarios: "24 horas",
          web: "https://www.cocheriasanjose.com.uy/",
          webdescripcion: "https://www.cocheriasanjose.com.uy/",
        }}
      ></PublicidadImageCard> */}
      <PublicidadCard
        comercio={{
          rubros:
            "Servicios Funerarios - Servicio de Traslado - Servicio de Cremación",
          nombre: "Cochería y Previsora Del Norte",
          datos:
            "Dirección Leandro Gomez 1447 Paysandú, Uruguay. Teléfono 4723 5629 – 4723 2203 Mail  info@grupodelnorte.com.uy",
          horarios: "Lunes a Viernes: 08:00 -19:00, Sábado: 08:00 a 12:00",
          web: "https://delnorte.com.uy/web/",
          webdescripcion: "https://delnorte.com.uy/web/",
        }}
      ></PublicidadCard>
      <PublicidadCard
        comercio={{
          rubros: "Diario - Semanario - Clasificados",
          nombre: "Avisos fúnebres - Diario El Telégrafo, Paysandú, Uruguay",
          datos:
            "Dirección 18 de Julio 1027 Paysandú, Uruguay. Teléfono 4722 3141 - Email redaccion@eltelegrafo.com",
          horarios: "Lunes a Viernes: 08:00 -18:00",
          web: "https://www.eltelegrafo.com/funebres/",
          webdescripcion: "https://www.eltelegrafo.com",
        }}
      ></PublicidadCard>
      <PublicidadCard
        comercio={{
          rubros: "Florería",
          nombre: "Corona's Florería",
          datos:
            "Dirección J. Pedro Varela 747 Paysandú, Uruguay. Teléfono 4723 8879 Celular: +598 91 244 662",
          horarios: "24 horas",
          web: "https://www.facebook.com/coronasfloreria/",
          webdescripcion: "https://www.facebook.com/coronasfloreria/",
        }}
      ></PublicidadCard>
      <PublicidadCard
        comercio={{
          rubros: "Florería",
          nombre: "Mil Flores",
          datos:
            "Dirección L. Gómez 1140 Paysandú, Uruguay. Teléfono 4722 0296 Celulares: +598 92 155 205 - 92 640 735",
          horarios: "24 horas",
          web: "https://www.facebook.com/milflorespaysandu/",
          webdescripcion: "https://www.facebook.com/milflorespaysandu/",
        }}
      ></PublicidadCard>
      <PublicidadCard
        comercio={{
          rubros: "Florería",
          nombre: "Clavarino",
          datos:
            "Dirección L. Gómez 1067 Paysandú, Uruguay. Teléfono 4722 3144 Celular: +598 98 465 650",
          horarios:
            "De lunes a viernes 8:00-12:00 y 15:00-19:00 sábado: 8:00-12:30 domingo: CERRADO",
          web: "https://www.facebook.com/floreria.clavarino/",
          webdescripcion: "https://www.facebook.com/floreria.clavarino/",
        }}
      ></PublicidadCard>
    </div>
  );
};

export default Services;
