import React from "react";
import moment from "moment/min/moment-with-locales";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const ObituaryCard = (props) => {
  // Set foramto de fecha y hora
  moment.locale("es");
  return (
    <div className="card bg-light mb-2">
      <div className="card-header">
        <div className="d-flex ">
          <div className="mr-auto">
            {moment(props.servicio.fecharegistro.toDate()).format("ll")}{" "}
          </div>
          {/* <h4 className="p-2">
            <AiOutlineFileAdd
              className="myButton"
              onClick={() => props.addObituary(props.servicio)}
            />
          </h4> */}
          <h4 className="p-2">
            <AiOutlineEdit
              className="myButton"
              onClick={() => props.editObituary(props.servicio)}
            />
          </h4>
          <h4 className="p-2">
            <AiOutlineDelete
              className="myButton"
              onClick={() => props.deleteObituary(props.servicio.id)}
            />
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="mr-auto p-2">
          <h4 className="card-title">{props.servicio.nombre} </h4>
        </div>
        {props.tweens && (
          <p className="card-text">
            <strong>Empresa: </strong> {props.servicio.empresa}
          </p>
        )}
        {props.tweens &&
          (props.servicio.deleted ? (
            <p className="card-text">
              <strong>BORRADO: </strong> SI
            </p>
          ) : (
            <p className="card-text">
              <strong>BORRADO: </strong> NO
            </p>
          ))}
        {props.tweens &&
          (props.servicio.paymentStatus ? (
            <p className="card-text">
              <strong>STATUS: </strong> {props.servicio.paymentStatus}
            </p>
          ) : (
            <p className="card-text">
              <strong>STATUS: </strong> sin info
            </p>
          ))}
        <p className="card-text">{props.servicio.obit}</p>
        <strong>{props.servicio.sala}</strong>
      </div>
    </div>
  );
};

export default ObituaryCard;
