import React, { useContext, useEffect, useState } from "react";

import "firebase/firestore";
import { useFirebaseApp, useUser } from "reactfire";

import moment from "moment/min/moment-with-locales";

import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import PagNumber from "../pagination/pagination";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { UserContext } from "../../store/UserContext";
import { FaBusinessTime } from "react-icons/fa";

const RegistroList = (props) => {
  const { userData } = useContext(UserContext);

  const user = useUser();
  moment.globalLocale = "es";
  // Set foramto de fecha y hora
  moment.locale("es");
  const db = useFirebaseApp().firestore();

  /****************************************************
   * Constantes para la paginacion
   */
  const postsPerPage = 10;
  const [originalList, setOriginalList] = useState([]);
  const [list, setList] = useState([]);
  const [business, setBusiness] = useState([]);
  // const [currentPosts, setCurrentPosts] = useState([]);
  // const [sumas, setSumas] = useState({});
  const [tweens, setTweens] = useState(userData && userData.rol === "admin");

  useEffect(() => {
    if (userData && userData._userData && userData._userData.rol === "admin") {
      setTweens(true);
    }
  });

  // useEffect(() => {
  //   obtenerPosts();
  // }, [list]);

  // /**
  //  * Metodo que obteiene la lista en la plataforma
  //  */
  // const obtenerPosts = async () => {
  //   //SI esta logueado
  //   if (user) {
  //     if (list) {
  //       const iolp = 1 * postsPerPage;
  //       const iofp = iolp - postsPerPage;
  //       const cp = list.slice(iofp, iolp);
  //       //setList(list);
  //       setCurrentPosts(cp);
  //     } else {
  //       goHome();
  //     }
  //   }
  // };

  const getBusiness = async () => {
    if (userData && userData._userData && userData._userData.rol === "admin") {
      db.collection("business").onSnapshot((querySnapshot) => {
        const docs = {};
        querySnapshot.forEach((doc) => {
          docs[doc.data().id] = doc.data().nombre;
        });
        console.log("BUSINESS DATA: ", docs);
        docs[-1] = "Admin";
        docs[-2] = "Usuarios";
        setBusiness(docs);
      });
    } else {
      goHome();
    }
  };

  const getData = async () => {
    console.log(userData);
    if (userData && userData._userData && userData._userData.rol === "admin") {
      db.collection("obituary")
        .orderBy("fecharegistro", "desc")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().empresaid === "") {
              docs.push({
                ...doc.data(),
                yyyy: moment(doc.data().fecharegistro.toDate()).format("YYYY"),
                mm: moment(doc.data().fecharegistro.toDate()).format("MM"),
                id: doc.id,
                sum: 1,
                empresaid: -2,
              });
            } else {
              docs.push({
                ...doc.data(),
                yyyy: moment(doc.data().fecharegistro.toDate()).format("YYYY"),
                mm: moment(doc.data().fecharegistro.toDate()).format("MM"),
                id: doc.id,
                sum: 1,
              });
            }
          });

          const output = docs.reduce((acc, cur, i, array) => {
            var code = cur.mm + "_" + cur.yyyy + "_" + cur.empresaid;
            if (acc[code]) {
              acc[code] = {
                id: code,
                yyyy: cur.yyyy,
                mm: cur.mm,
                empresaid: cur.empresaid,
                suma: ++acc[code].suma,
              };
            } else {
              acc[code] = {
                id: code,
                yyyy: cur.yyyy,
                mm: cur.mm,
                empresaid: cur.empresaid,
                suma: 1,
              };
            }
            return acc;
          }, {});
          console.log("OUTPUT: ", output);
          setList(Object.values(output));
          setOriginalList(Object.values(output));
        });
    } else {
      goHome();
    }
  };

  useEffect(() => {
    getBusiness();
    getData();
  }, [userData]);
  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Obituarios por mes");
  });

  const history = useHistory();
  const goHome = () => history.push("/");

  /***********************************************************
   * Variables para el manejo del Input SEARCH
   * variable de estado y handleChange
   */

  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    //var post = totalDatos.slice(0, totalDatos.length);
    setList(filterItemsObituary(originalList, e.target.value));
  };

  const filterItemsObituary = (p, query) => {
    if (query) {
      return p.filter((el) => {
        return (
          moment(el.fecharegistro.toDate())
            .format("DD-MM-YYYY")
            .indexOf(query) > -1 ||
          el.email.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.mensaje.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.nombre.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.telefono.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    } else {
      return p;
    }
  };

  return (
    <div>
      <div className="h5">
        Conteo de publicaciones de obituarios realizadas, segmentados por
        publicador, mes y año
      </div>

      {tweens &&
        userData &&
        userData._userData &&
        userData._userData.rol === "admin" &&
        business && (
          <div>
            {/* <div className="d-none d-lg-block d-xl-block"> */}
            <div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Mes/Año</th>
                    <th scope="col">Empresa</th>
                    <th scope="col">#Obituarios</th>
                    {/* <th scope="col">#Obituarios(más 1h)</th> */}
                  </tr>
                </thead>
                <tbody>
                  {originalList.length > 0 &&
                    originalList.map((servicio) => {
                      return (
                        <tr className="table-light" key={servicio.id}>
                          <td>
                            {servicio.mm} - {servicio.yyyy}
                          </td>
                          <td>{business[servicio.empresaid]}</td>
                          <td>{servicio.suma}</td>
                          {/* <td>{servicio.suma2}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </div>
  );
};

export default RegistroList;
