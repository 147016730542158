import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import moment from "moment";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

const ObituarySmall = (props) => {
  const history = useHistory();
  const goObituaryForm = () =>
    history.push("/formulario/condolencia/" + values.id);

  const [modal, setModal] = useState({ modal: false, isOpen: true });
  /******
   * Metodo que captura las acciones del modal y lo apaga
   */
  const toggleModal = () => {
    setModal({
      modal: !modal.modal,
      isOpen: false,
    });
  };

  const values = {
    nombre: props.nombre,
    empresa: props.empresa,
    id: props.id,
  };

  const deceased = props.deceased;

  const handleClick = () => {
    goObituaryForm();
  };

  const url = "https://enmemoria.com.uy";
  const mensajeRedes =
    " Informamos el fallecimiento de " +
    values.nombre +
    ", el día " +
    moment(deceased.fehcafallecimiento).format("LL") +
    " - " +
    values.empresa;

  return (
    <div>
      <Modal isOpen={modal.modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}> {deceased.nombre}</ModalHeader>
        <ModalBody>
          <div className="p-4">
            {" "}
            <p>
              <label>
                <strong>
                  Falleció el día{" "}
                  {moment(deceased.fehcafallecimiento).format("LL")}
                </strong>
              </label>
            </p>
            <p>
              {deceased.empresa && (
                <label>
                  <strong>Empresa:</strong> {deceased.empresa}
                </label>
              )}
              {deceased.sala && (
                <label>
                  <strong> Sala: </strong> {deceased.sala}
                </label>
              )}
            </p>
            {deceased && deceased.fechainicio && (
              <p>
                <label>
                  <strong>Velatorio:</strong>{" "}
                  {moment(deceased.fechainicio).format("LL")}
                  {" a las "}
                  {deceased.horainicio}
                  {" al "}
                  {moment(deceased.fechafinaliza).format("LL")}
                  {" a las "} {deceased.horafinaliza}
                </label>
              </p>
            )}
            {deceased && deceased.fechasepelio && (
              <p>
                <label>
                  <strong>Sepelio: </strong>
                  {moment(deceased.fechasepelio).format("LL")}{" "}
                  {deceased.horasepelio && (
                    <>
                      {" a las "}
                      {deceased.horasepelio}
                      {" - "}
                    </>
                  )}
                  <strong>{deceased.cementerio}</strong>
                </label>
              </p>
            )}
            {deceased && deceased.nota && (
              <p>
                <label>
                  {" "}
                  <strong>{deceased.nota}</strong>
                </label>
              </p>
            )}
          </div>
          {!deceased.deleted && (
            <button
              type="button"
              className="btn btn-success"
              onClick={handleClick}
            >
              {" "}
              Enviar saludo
            </button>
          )}
        </ModalBody>
        <ModalFooter>
          <p>
            <WhatsappShareButton url={url} title={mensajeRedes}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>{" "}
            <TelegramShareButton url={url} title={mensajeRedes}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>{" "}
            <EmailShareButton url={url} title={mensajeRedes}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>{" "}
            <FacebookMessengerShareButton url={url} title={mensajeRedes}>
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
          </p>
        </ModalFooter>
      </Modal>

      <div className="card mb-3 myButton" onClick={toggleModal}>
        <div className="card-body">
          <div className="row d-flex justify-content-between">
            <div className="col-md-9">
              <strong>{values.nombre.toUpperCase()}</strong>
              <div className="m-2">
                Falleció el {moment(deceased.fehcafallecimiento).format("LL")}{" "}
              </div>
              <div className="m-2">{values.empresa} </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-success "
                  onClick={handleClick}
                >
                  {" "}
                  Enviar saludo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObituarySmall;
