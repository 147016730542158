import React, { useEffect, useState } from "react";
import { useFirebaseApp, useUser } from "reactfire";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { _obituaryPrice } from "../../EnMemoriaConfig";

const Aboutus = (props) => {
  let history = useHistory();
  const goBack = () => history.goBack();

  const [message, setMessage] = useState({});
  const [aviso, setAviso] = useState("");

  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Contáctanos");
  });

  const db = useFirebaseApp().firestore();
  const firebase = useFirebaseApp();
  const user = useUser();

  const sendMessage = async () => {
    if (
      message.nombre &&
      (message.email || message.telefono) &&
      message.mensaje
    ) {
      const now = Date.now();
      const created = firebase.firestore.Timestamp.fromDate(new Date(now));
      var userUid = "";
      if (user) {
        userUid = user.uid;
      }
      const result = await db.collection("message").add({
        ...message,
        fecharegistro: created,
        uid: userUid,
      });
      if (result.id) {
        toast(
          "El mensaje se ha enviado correctamente, en la brevedad posible nos comunicaremos. Gracias",
          {
            type: "success",
          }
        );
      } else if (!result.id) {
        toast(
          "No hemos podido enviar tu mensaje, por favor intentalo más tarde",
          {
            type: "alert",
          }
        );
      }
      goBack();
    } else {
      setAviso(
        "Es necesario ingresar nombre, datos de contacto y mensaje. Gracias"
      );
    }
  };

  const handleChange = (e) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  return (
    <div className="card bg-light mb-3">
      <div className="card-header">
        {" "}
        <h4>
          <strong>EnMemoria.com.uy</strong>
        </h4>
      </div>
      <div className="card-body">
        <p>
          Avisos fúnebres y publicación de obituarios en Uruguay, datos útiles p
          ara el envío de flores, traslados, clasificados de hoy, obituarios del
          día, fúnebres de la fecha y busquedas, entre otros servicios para
          empresas.
        </p>
        <p>
          Artigas, Canelones, Cerro Largo, Colonia, Durazno, Flores, Florida,
          Lavalleja, Maldonado, Montevideo, Paysandú, Río Negro, Rivera, Rocha,
          Salto, San José, Soriano, Tacuarembó, Treinta y Tres. D
        </p>
        <p className="card-text">contactanos.</p>
        <a
          className="btn btn-success"
          href="https://enmemoria.com.uy/contactanos"
        >
          CONTACTANOS
        </a>
      </div>
    </div>
  );
};

export default Aboutus;
