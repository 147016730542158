import React from "react";

const PublicidadCard = (props) => {
  return (
    <div>
      <div className="card bg-light mb-3">
        <div className="card-header">{props.comercio.rubros}</div>
        <div className="card-body">
          <h4 className="card-title">{props.comercio.nombre}</h4>
          <p className="card-text"></p>
          <p className="card-text">{props.comercio.datos}</p>
          <p className="card-text">{props.comercio.horarios}</p>
          <p className="card-footer">
            <a href={props.comercio.web} target="_blank">
              {props.comercio.webdescripcion}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublicidadCard;
