import React, { useState } from "react";
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Footer = () => {
  const [modalInfo, setModalInfo] = useState(false);
  const toggleInfoModal = () => {
    setModalInfo(!modalInfo);
  };

  const _url = "https://enmemoria.com.uy";
  const _title = "sitio con información online";

  return (
    <div>
      <Modal isOpen={modalInfo} toggle={toggleInfoModal}>
        <ModalHeader toggle={toggleInfoModal}>
          <strong className="p-3">Términos y Condiciones de Uso</strong>
        </ModalHeader>
        <ModalBody>
          {/* TERMINOS Y CONDICIONES  */}
          {/* <p>
            <strong>Términos y Condiciones de Uso</strong>{" "}
          </p> */}
          <p className="text-justify">
            <strong>EnMemoria.com.uy</strong>, ofrece la posibilidad de informar
            sobre los fallecimientos y contribuir a comunicar los datos de los
            velatorios y sepelios, brindando además la posibilidad de publicar
            esquelas y acompañar a las familias en su duelo.
          </p>
          <p>
            {" "}
            <strong>REGISTRO. CONSENTIMIENTO </strong>{" "}
          </p>
          <p className="text-justify">
            Es requisito necesario para la adquisición o utilización de los
            productos y/o servicios que se ofrecen en este sitio, que lea y
            acepte los siguientes Términos y Condiciones que a continuación se
            redactan. El cliente/usuario, al aceptar electrónicamente este
            contrato (al hacer click en “acepto”, ingresar al sistema o hacer
            uso de los servicios por cualquier medio) estará prestando su
            conformidad a estos términos y condiciones; el cliente/usuario debe
            leer y comprender el alcance de este documento y contar con la
            capacidad jurídica necesaria para utilizar o contratar, ya sea en
            nombre propio o en representación de una persona jurídica. El uso de
            nuestros servicios así como la compra de nuestros productos
            implicará que usted ha leído y aceptado los Términos y Condiciones
            de Uso en el presente documento. Para utilizar un producto y/o
            servicio, será necesario el registro por parte del cliente/usuario,
            con ingreso de datos personales fidedignos y definición de una
            contraseña en caso de requerirse. El cliente/usuario puede elegir y
            cambiar la clave para su acceso de administración de la cuenta en
            cualquier momento, en caso de que se haya registrado y que sea
            necesario para la utilización, compra de alguno de nuestros
            productos y/o servicios. <strong>EnMemoria.com.uy</strong> no asume
            la responsabilidad en caso de que entregue dicha clave a terceros.
            Todas las compras y transacciones que se lleven a cabo por medio de
            este sitio web, están sujetas a un proceso de confirmación y
            verificación, el cual podría incluir la verificación y
            disponibilidad de producto, validación de la forma de pago,
            validación de la factura (en caso de existir) y el cumplimiento de
            las condiciones requeridas por el medio de pago seleccionado. En
            algunos casos puede que se requiera una verificación por medio de
            correo electrónico. El Servicio será facturado una vez realizada la
            publicación y se acepte el pago, la factura se enviará por medios
            digitales. La publicación deberá ser abonada a través de Mercado
            Pago que acepta tarjetas pre pagas y tarjetas de crédito así como
            pagos en redes de cobranza y publicadas una vez se confirme el
            mismo. En el correspondiente recibo de la tarjeta de crédito del
            Usuario figurará el pago como un consumo de Mercado Pago dado que
            esta empresa facilitará el servicio de pago electrónico. En la
            tarjeta de crédito el cobro del Servicio será en pesos uruguayos.
            Nos reservamos el derecho de cambiar nuestros precios para los
            productos que se muestran en cualquier momento y de corregir los
            errores de precios que pueden ocurrir inadvertidamente así como
            limitar o suprimir bonificaciones u otros planes o condiciones.
            <strong>EnMemoria.com.uy</strong> se reserva el derecho de suprimir
            cualquier participación, publicación o cuenta que considere, sin que
            ello implique algún tipo de acción legal por parte del usuario y/o
            cliente Los precios de los productos y/o servicios ofrecidos son
            válidos solamente en las compras realizadas en este sitio web.
          </p>
          <p>
            {" "}
            <strong>LICENCIA </strong>
          </p>
          <p className="text-justify">
            Los proveedores de la aplicación a través de su sitio web conceden
            una licencia para que los usuarios utilicen los productos y/o
            servicios que son vendidos en este sitio web de acuerdo a los
            Términos y Condiciones que se describen en este documento.
          </p>
          <p>
            {" "}
            <strong>PROPIEDAD </strong>
          </p>
          <p className="text-justify">
            Usted no puede declarar propiedad intelectual o exclusiva a ninguno
            de nuestros productos, modificado o sin modificar. Todos los
            productos son propiedad de los proveedores del contenido. El
            cliente/usuario reconoce y acepta que los derechos de propiedad
            intelectual y/o industrial del sistema y sus desarrollos presentes y
            futuros, corresponden al proveedor siendo el único titular de los
            derechos morales y económicos del sistema y sus desarrollos
            sucesivos. En caso de que no se especifique lo contrario, nuestros
            productos se proporcionan sin ningún tipo de garantía, expresa o
            implícita.
          </p>
          <p>
            <strong>RESPONSABILIDAD </strong>
          </p>
          <p className="text-justify">
            La obligación del proveedor se limita a brindar los productos y
            servicios enumerados en este documento o sus actualizaciones, no
            garantizando que los servicios se prestarán sin errores o en forma
            ininterrumpida, ni que cumpla con las expectativas ni
            funcionalidades que el cliente requiere. El sistema se ofrece “tal
            como es”, por lo que es de exclusiva responsabilidad del
            cliente/usuario conocer el alcance, aptitudes y funcionalidades del
            servicio.- El cliente/usuario acepta que el proveedor no controla la
            transferencia de datos a través de sistemas de comunicaciones y que
            los servicios podrán estar sujetos a limitaciones o demoras
            inherentes al uso de dichos sistemas de comunicaciones. En ningún
            caso el proveedor será responsable por los daños indirectos o no
            inmediatos, lucro cesante, ahorros no producidos, o cualquier
            reclamación hecha al cliente/usuario por un tercero. El
            cliente/usuario es el único responsable por los datos que aloja en
            el sistema, el proveedor no controla ni audita dichos datos para
            verificar la legalidad ni la posible violación de derechos o normas.
            El proveedor no será responsable por la pérdida total o parcial,
            imposibilidad de acceso, sea transitoria o definitiva de los datos
            que en el sistema se encuentren. En ningún caso el proveedor será
            responsable de ningún daño incluyendo, pero no limitado a, daños
            directos, indirectos, especiales, fortuitos o consecuentes u otras
            pérdidas resultantes del uso o de la imposibilidad de utilizar
            nuestros productos.
          </p>
          <p>
            <strong>COMPROBACIÓN ANTIFRAUDE</strong>{" "}
          </p>
          <p className="text-justify">
            La compra del cliente puede ser aplazada para la comprobación
            antifraude. También puede ser suspendida por más tiempo para una
            investigación más rigurosa, para evitar transacciones fraudulentas.
          </p>
          <p>
            <strong>PRIVACIDAD</strong>
          </p>
          <p className="text-justify">
            Enmemeoria.com garantiza que la información personal que usted envía
            cuenta con la seguridad necesaria. Los datos ingresados por usuario
            o en el caso de requerir una validación de los pedidos no serán
            entregados a terceros, salvo que deba ser revelada en cumplimiento a
            una orden judicial o requerimientos legales. La suscripción a
            boletines de correos electrónicos publicitarios es voluntaria y
            podría ser seleccionada al momento de crear su cuenta. El
            propietario reserva los derechos de cambiar o de modificar estos
            términos sin previo aviso. Estos Términos y Condiciones se rigen e
            interpretan de conformidad con las leyes de la República Oriental
            del Uruguay, siendo competentes los tribunales de la República.-
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={toggleInfoModal}>
            Cerrar
          </button>
        </ModalFooter>
      </Modal>
      <footer className="card">
        {/* <div className="text-center text-black bg-light">      
            <h5 className="text-uppercase text-center py-3">Informacion Funebre</h5>
            <p className="text-center py-3">Notas y saludos fúnebres, para informar y citar a participar del homenaje a nuestros seres queridos.</p>    
      </div>       */}

        <div className="text-center text-white navbar-dark bg-primary py-3">
          <h5 className="text-uppercase text-center py-3">En Memoria</h5>
          <p className="text-center ">
            Un lugar para informar y participar del homenaje a nuestros seres
            queridos.
          </p>
          <p>
            <WhatsappShareButton url={_url} title={_title}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>{" "}
            <TelegramShareButton url={_url} title={_title}>
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>{" "}
            <EmailShareButton url={_url} title={_title}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>{" "}
            <FacebookMessengerShareButton url={_url} title={_title}>
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
          </p>
        </div>

        <div className="footer-copyright text-center text-white navbar-dark bg-primary py-3">
          © 2020 Información y obituarios - info@enmemoria.com.uy
          {/* <a className="btn-link" href="https://enmemoria.com.uy">
            {" "}
            https://enmemoria.com.uy
          </a> */}
          <div>
            <button
              type="button"
              className="btn btn-link"
              name="btnCondicion"
              onClick={toggleInfoModal}
            >
              TERMINOS Y CONDICIONES
            </button>
            {/* <button
              type="button"
              className="btn btn-link"
              name="btnPolitica"
              onClick={toggleDatosModal}
            >
              TRATAMIENTO DE DATOS
            </button> */}
            <div className="badge d-flex justify-content-center">
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/freepik"
                title="Freepik"
              >
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                {" "}
                www.flaticon.com
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
