import React, { useState, useEffect } from "react";
import { FaCross, FaStarOfDavid, FaRibbon } from "react-icons/fa";
import { GiShutRose } from "react-icons/gi";
import { Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
//import "moment/locale/es";

const ObituaryEdit = (props) => {
  moment.locale("es");

  const handleChange = (e) => {
    props.setObituary({ ...props.registro, obit: e.target.value });
  };

  return (
    <div>
      <div className="card border-dark mb-3">
        <div className="card-header">
          <h4>
            <div className="d-flex bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                {props.registro.ico === "FaCross" && <FaCross />}
                {props.registro.ico === "FaStarOfDavid" && <FaStarOfDavid />}
                {props.registro.ico === "GiShutRose" && <GiShutRose />}
                {props.registro.ico === "FaRibbon" && <FaRibbon />}
              </div>
              <div className="p-2 bd-highlight"> {props.registro.nombre}</div>
            </div>
          </h4>
        </div>
        <div className="card-body">
          <h4 className="card-title">
            <Input
              type="textarea"
              name="obit"
              value={props.registro.obit}
              onChange={handleChange}
            ></Input>
          </h4>
          <p className="card-text">{props.registro.empresa}</p>
        </div>
      </div>
    </div>
  );
};

export default ObituaryEdit;
