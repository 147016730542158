import React, { useState } from "react";

const Banner = (props) => {
  const deptos = [
    "Artigas",
    "Canelones",
    "Cerro Largo",
    "Colonia",
    "Durazno",
    "Flores",
    "Florida",
    "Lavalleja",
    "Maldonado",
    "Montevideo",
    "Paysandú",
    "Río Negro",
    "Rivera",
    "Rocha",
    "Salto",
    "San José",
    "Soriano",
    "Tacuarembó",
    "Treinta y Tres",
  ];

  const [deptoSelected, setDeptoSelected] = useState("Sin especificar");

  const handleChange = (e) => {
    setDeptoSelected(e.target.value);
  };

  return (
    <div className="p-2 container">
      {props.smview && (
        <div className="p-2">
          <div className="text-center m-4 d-lg-none">
            <h1>{props.title}</h1>
          </div>
          <div className="d-none d-lg-block">
            <div className="jumbotron">
              <h1 className="display-4 onImageText">{props.title}</h1>
              <p className="onImageText">
                En memoria de nuestros seres queridos que siempre estarán
                presentes en nuestras vidas.
              </p>
            </div>
          </div>
        </div>
      )}

      {!props.smview && (
        <div className="jumbotron">
          <h1 className="display-4 onImageText">{props.title}</h1>
          <p className="onImageText">
            En memoria de nuestros seres queridos que siempre estarán presentes
            en nuestras vidas.
          </p>
        </div>
      )}
    </div>
  );
};

export default Banner;
