import React, { lazy, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./component/navbar/Navbar";
import Banner from "./component/banner/Banner";
import ObituaryMain from "./component/obituary/ObituaryMain";
import ObituaryForm from "./component/obituary/ObituaryForm";
import ObituaryCompleteForm from "./component/obituary/ObituaryCompleteForm";
import ServicesPost from "./component/company/ServicesPosts";
import ObituaryPost from "./component/company/ObituaryPosts";
import DeceasedList from "./component/Admin/DeceasedList";
import RegistroList from "./component/Admin/RegistroList";
import MessageList from "./component/Admin/MessageList";
import Services from "./component/services/Services";
import Aboutus from "./component/Aboutus/Aboutus";
import Contact from "./component/Aboutus/Contact";
import Footer from "./component/footer/Footer";
import NotFoundPage from "./component/notFound/PageNotFound";
//Pages
import Ob_admin from "./pages/admin/Ob_admin";
import Se_admin from "./pages/admin/Se_admin";
import Ob_SanjosePdu from "./pages/sanjose/Ob_SanjosePdu";
import Se_SanjosePdu from "./pages/sanjose/Se_SanJosePdu";
import ObituaryListSanJose from "./pages/sanjose/ObituaryListSanJose";

//import { ContextStore } from "./store/context";
import { UserContext } from "./store/UserContext";

//import Payment from "./component/payment/payment";

import "firebase/firestore";
//import queryString from "query-string";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const ObituaryMain = React.lazy(() =>
//   import("./component/obituary/ObituaryMain")
// );

function App() {
  const [main, setMain] = useState("En memoria");
  const [smview, setSmview] = useState(false);
  const [search, setSearch] = useState();
  const [userData, setUserData] = useState();
  const [header, setHeader] = useState(true);
  // const values = useMemo(() => ({ userData, setUserData }), [
  //   userData,
  //   setUserData,
  // ]);

  const handleMain = (mainPage, smview) => {
    setMain(mainPage);
    setSmview(smview);
  };

  const handleSearch = (searchString) => {
    //Realizar busquedas en Home
    setSearch(searchString);
  };

  return (
    <div className="">
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <div>
            {/* <Payment></Payment> */}
            <ToastContainer></ToastContainer>
            {header && (
              <div>
                <NavBar search={handleSearch}></NavBar>
                <Banner title={main} smview={smview}></Banner>
              </div>
            )}

            <Switch>
              {/* <Route
              path="/formulario/condolencia/:paramId"
              render={(props) => (
                <ContextStore comp={<ObituaryForm handleMain={handleMain} />} />
              )}
            /> */}
              {/* <Route path="/geoplugin_activation.html">
                <div className="container-flex">
                  hGcbhyZb74Ux3I0U4Cc6IvIQXFQ1cM
                </div>
              </Route> */}
              <Route path="/pantalla/obituarios">
                <div className="container-flex">
                  <Ob_admin setHeader={setHeader}></Ob_admin>
                </div>
              </Route>
              <Route path="/pantalla/servicios">
                <div className="container-flex">
                  <Se_admin setHeader={setHeader}></Se_admin>
                </div>
              </Route>
              <Route path="/cocheriasanjose/obituarios">
                <div className="container-flex">
                  <Ob_SanjosePdu setHeader={setHeader}></Ob_SanjosePdu>
                </div>
              </Route>
              <Route path="/cocheriasanjose/servicios">
                <div className="container-flex">
                  <Se_SanjosePdu setHeader={setHeader}></Se_SanjosePdu>
                </div>
              </Route>
              <Route path="/cocheriasanjose/fallecimientos">
                <div className="container">
                  <ObituaryListSanJose
                    setHeader={setHeader}
                  ></ObituaryListSanJose>
                </div>
              </Route>
              <Route path="/formulario/condolencia/:paramId">
                <div className="container">
                  <ObituaryForm handleMain={handleMain}></ObituaryForm>
                </div>
              </Route>
              <Route path="/empresa/obituarios">
                <div className="container">
                  <ObituaryPost handleMain={handleMain}></ObituaryPost>
                </div>
              </Route>
              <Route path="/empresa/servicios">
                <div className="container">
                  <ServicesPost handleMain={handleMain}></ServicesPost>
                </div>
              </Route>
              <Route path="/admin/deceased">
                <div className="container">
                  <DeceasedList handleMain={handleMain}></DeceasedList>
                </div>
              </Route>
              <Route path="/admin/registros">
                <div className="container">
                  <RegistroList handleMain={handleMain}></RegistroList>
                </div>
              </Route>
              <Route path="/admin/messages">
                <div className="container">
                  <MessageList handleMain={handleMain}></MessageList>
                </div>
              </Route>
              <Route path="/empresa/formulario">
                <div className="container">
                  <ObituaryCompleteForm
                    handleMain={handleMain}
                  ></ObituaryCompleteForm>
                </div>
              </Route>
              <Route path="/obituario">
                <div className="container">
                  <ObituaryForm handleMain={handleMain}></ObituaryForm>
                </div>
              </Route>
              <Route path="/sobrenosotros">
                <div className="container">
                  <Aboutus handleMain={handleMain}></Aboutus>
                </div>
              </Route>
              <Route path="/contactanos">
                <div className="container">
                  <Contact handleMain={handleMain}></Contact>
                </div>
              </Route>
              <Route path="/servicios">
                <div className="container">
                  <Services handleMain={handleMain}></Services>
                </div>
              </Route>
              <Route path="/">
                <div className="container">
                  <ObituaryMain
                    handleMain={handleMain}
                    search={search}
                  ></ObituaryMain>
                </div>
              </Route>
              <Route path="*">
                <div className="container">
                  <NotFoundPage></NotFoundPage>
                </div>
              </Route>
            </Switch>
            {header && <Footer></Footer>}
          </div>
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
