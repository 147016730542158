import React from "react";
import Button from "reactstrap/lib/Button";

class HideableText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
    };
  }

  toggleIsHidden() {
    this.setState((currentState) => ({
      isHidden: !currentState.isHidden,
    }));
  }

  render() {
    return (
      <div>
        <Button onClick={() => this.toggleIsHidden()}>Toggle </Button>
        {!this.state.isHidden && this.props.text}
      </div>
    );
  }
}

export default HideableText;
