import React from "react";
import { Link } from "react-router-dom";

class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Error 404 - ! Lo sentimos, esta página no esta disponible ¡</h1>
        <p className="text-center">
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}
export default NotFoundPage;
