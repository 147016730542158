import React, { useEffect, useState, useContext } from "react";

import "firebase/firestore";
import { useFirebaseApp, useUser } from "reactfire";
import moment from "moment/min/moment-with-locales";

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import ServiceCard from "./ServiceCard";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import PagNumber from "../pagination/pagination";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { UserContext } from "../../store/UserContext";

const ServicesPosts = (props) => {
  const { userData } = useContext(UserContext);
  const [rooms, setRooms] = useState([]);
  const [search, setSearch] = useState("");
  const user = useUser();
  moment.globalLocale = "es";
  const db = useFirebaseApp().firestore();

  /****************************************************
   * Constantes para la paginacion
   */
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPost, setTotalPost] = useState([]);
  const [originalList, setOriginalList] = useState([]);

  const [necropolis, setNecropolis] = useState([]);
  const [deptoNecropolis, setDeptoNecropolis] = useState("Paysandú");

  const [list, setList] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [tweens, setTweens] = useState(false);
  //   userData && userData._userData && userData._userData.rol === "admin"
  // );

  useEffect(() => {
    setTweens(
      userData && userData._userData && userData._userData.rol === "admin"
    );
  }, [userData]);
  /*PageNumber*/
  const paginate = (pageNumber) => {
    const iolp = pageNumber * postsPerPage;
    const iofp = iolp - postsPerPage;
    const cp = list.slice(iofp, iolp);
    setCurrentPosts(cp);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // if (
    //   user &&
    //   (user.email === "hugo.rivero@montevideo.com.uy" ||
    //     user.email === "vrrm@adinet.com.uy")
    // ) {
    //   setTweens(true);
    // }
  });
  useEffect(() => {
    obtenerPosts();
  }, [list]);

  const getRooms = () => {
    db.collection("rooms")
      .where("empresa", "==", userData._userData.empresa)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (!doc.data().deleted) {
            docs.push({ ...doc.data(), id: doc.id });
          }
        });
        setRooms(docs);
        //setObituary({ ...obituary, empresa: userData._businessData.nombre });
      });
  };

  useEffect(() => {
    if (
      userData &&
      userData._userData &&
      userData._userData.rol === "business"
    ) {
      getRooms();
    }
  }, [userData]);

  /**
   * Metodo que obteiene la lista de negocios registrados en la plataforma
   */
  const obtenerPosts = async () => {
    //SI esta logueado
    if (user) {
      if (list) {
        const iolp = 1 * postsPerPage;
        const iofp = iolp - postsPerPage;
        const cp = list.slice(iofp, iolp);
        setTotalPost(list);
        setList(list);
        setCurrentPosts(cp);
      } else {
        goHome();
      }
    }
  };

  const [item, setItem] = useState([]);

  const getData = async () => {
    if (user) {
      db.collection("deceased")
        .orderBy("fecharegistro", "desc")
        .onSnapshot((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            if (
              (userData &&
                userData._userData &&
                userData._userData.rol === "admin") ||
              (doc.data().uid === user.uid && !doc.data().deleted)
            ) {
              docs.push({ ...doc.data(), id: doc.id });
            }
          });
          //setItem(docs);
          setOriginalList(docs);
          setList(docs);
        });
    }
  };

  const deleteService = (id) => {
    if (user) {
      confirmAlert({
        title: "Confirmar eliminación",
        message: "¿Desea eliminar la publicación?.",
        buttons: [
          {
            label: "Si",
            onClick: () => {
              db.collection("deceased").doc(id).update({ deleted: true });
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  const addObituary = (servicio) => {
    if (user) {
      history.push("/formulario/condolencia/" + servicio.id);
    }
  };

  const filterItemsSmall = () => {
    const query = search;
    if (query) {
      return item.filter((el) => {
        return (
          el.nombre.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          // el.empresa.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
          el.obit.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      });
    } else {
      return item;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getNecropolis();
  }, []);

  useEffect(() => {
    const setmain = props.handleMain;
    setmain("Servicios");
  });

  const history = useHistory();
  const goHome = () => history.push("/");

  const goForm = () => {
    history.push("/empresa/formulario");
  };

  /***********************************************************
   * Variables para el manejo del Input SEARCH
   * variable de estado y handleChange
   */

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    //var post = totalDatos.slice(0, totalDatos.length);
    setList(filterItemsServices(originalList, e.target.value));
  };
  /**********************************************************************
   * SEARCH ENGINE
   */

  /******************************
   * funcion auxiliar -Normalizar: dado un string lo retorna sin tíldes
   /************************* */
  const noTilde = (s) => {
    if (s.normalize != undefined) {
      s = s.normalize("NFKD");
    }
    //quitamos el tilde, dieresis, etc de la forma NFKD
    return s.replace(/[\u0300-\u036F]/g, "");
  };

  /************************************************************
   * funcion para el filtrado de la coleccion de post
   */
  const filterItemsServices = (p, query) => {
    if (query) {
      return p.filter((el) => {
        if (tweens) {
          var texto =
            moment(el.fecharegistro.toDate()).format("lll") +
            " " +
            el.empresa.toLocaleLowerCase() +
            " " +
            el.nombre.toLowerCase() +
            " " +
            el.username.toLowerCase() +
            " ";
        } else {
          var texto =
            moment(el.fecharegistro.toDate()).format("lll") +
            " " +
            el.nombre.toLowerCase() +
            " " +
            el.sala.toLowerCase() +
            " " +
            el.cementerio.toLowerCase() +
            " ";
        }
        console.log(texto);
        //solo retornamos los elementos que contangan todas las palabras buscadas
        return query
          .split(" ")
          .every((palabra) =>
            noTilde(texto.toLocaleLowerCase()).includes(
              noTilde(palabra.toLowerCase())
            )
          );
      });
    } else {
      return p;
    }
  };

  /*********************
   * Modal Innfo Obituario
   */

  const infoService = (servicio) => {
    setServicioActual(servicio);
    toggleInfoModal();
  };
  const [modalInfo, setModalInfo] = useState(false);
  const [servicioActual, setServicioActual] = useState("");
  const toggleInfoModal = () => {
    setModalInfo(!modalInfo);
  };
  const handleChangeServicioActual = (e) => {
    setServicioActual({ ...servicioActual, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    setServicioActual({ ...servicioActual, [e.target.name]: e.target.value });
  };

  /*********************
   * Modal Edit Obituario
   */

  const editService = (servicio) => {
    setServicioActual(servicio);
    setDeptoNecropolis(servicio.deptocementerio);
    toggleEditModal();
  };
  const [modalEdit, setModalEdit] = useState(false);

  const toggleEditModal = () => {
    setModalEdit(!modalEdit);
  };

  const editarServicioActual = () => {
    toggleInfoModal();
    toggleEditModal();
  };
  const updateServicioActual = () => {
    toggleEditModal();
    db.collection("deceased").doc(servicioActual.id).update(servicioActual);
  };

  const deptos = [
    "Artigas",
    "Canelones",
    "Cerro Largo",
    "Colonia",
    "Durazno",
    "Flores",
    "Florida",
    "Lavalleja",
    "Maldonado",
    "Montevideo",
    "Paysandú",
    "Río Negro",
    "Rivera",
    "Rocha",
    "Salto",
    "San José",
    "Soriano",
    "Tacuarembó",
    "Treinta y Tres",
  ];

  const handleChangeDeptoCementerio = (e) => {
    setServicioActual({ ...servicioActual, deptocementerio: e.target.value });
    setDeptoNecropolis(e.target.value);
  };

  const getNecropolis = () => {
    db.collection("necropolis").onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        if (!doc.data().deleted) {
          docs.push({ ...doc.data(), id: doc.id });
        }
      });
      setNecropolis(docs);
    });
  };

  return (
    <div>
      <Modal isOpen={modalEdit} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>
          <strong>{servicioActual.nombre}</strong>
        </ModalHeader>
        <ModalBody>
          {/* Nombre  */}

          <div className="form-group">
            <label htmlFor="inputNombre">
              <strong>Nombre</strong>
            </label>
            <input
              type="text"
              className="form-control"
              id="inputNombre"
              name="nombre"
              value={servicioActual.nombre}
              onChange={handleChange}
              required
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="inputFechafallecimiento">
              <strong>Fecha deceso</strong>
            </label>
            <input
              type="date"
              className="form-control"
              id="inputFechafallecimiento"
              name="fehcafallecimiento"
              value={servicioActual.fehcafallecimiento}
              onChange={handleChange}
            ></input>
          </div>
          {/* Empresa funebre */}
          <div className="form-group">
            {/* Sala */}

            <div className="row">
              <div className="col-sm-12">
                <label>
                  <strong>Velatorío</strong>
                </label>
              </div>
              <div className="col-sm-12">
                <label>Sala</label>
              </div>
              <div className="col-sm-12">
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "business" && (
                    <select
                      className="form-control"
                      id="selectSala"
                      name="sala"
                      value={servicioActual.sala}
                      onChange={handleChange}
                    >
                      <option key={-2} value={"Sin especificar"}>
                        {"Sin especificar"}
                      </option>
                      {rooms &&
                        rooms.map((option) => {
                          const sala =
                            option.sala +
                            " - " +
                            option.direccion +
                            " - " +
                            option.ciudad;
                          return (
                            <option key={option.id} value={sala}>
                              {sala}
                            </option>
                          );
                        })}
                      <option key={-3} value={"No se realiza velatorio"}>
                        {"No se realiza velatorio"}
                      </option>
                    </select>
                  )}
                {userData &&
                  userData._userData &&
                  userData._userData.rol === "admin" && (
                    <input
                      className="form-control"
                      id="selectSala"
                      name="sala"
                      value={servicioActual.sala}
                      onChange={handleChange}
                    ></input>
                  )}
              </div>
              <div className="col-sm-6">
                <label className=" col-form-label" htmlFor="inputFechainicio">
                  Fecha comienzo
                </label>
                <input
                  type="date"
                  className="form-control "
                  id="inputFechainicio"
                  name="fechainicio"
                  onChange={handleChange}
                  value={servicioActual.fechainicio}
                ></input>
              </div>
              <div className="col-sm-6">
                <label>Hora comienzo</label>
                <input
                  type="time"
                  className="form-control "
                  id="inputHorainicio"
                  name="horainicio"
                  onChange={handleChange}
                  value={servicioActual.horainicio}
                ></input>
              </div>
              <div className="col-sm-6">
                <label className=" col-form-label" htmlFor="inputFechafinaliza">
                  Fecha finalización
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="inputFechafinaliza"
                  name="fechafinaliza"
                  onChange={handleChange}
                  value={servicioActual.fechafinaliza}
                ></input>
              </div>
              <div className="col-sm-6">
                <label className=" col-form-label" htmlFor="inputHorafinaliza">
                  Hora finalización
                </label>
                <input
                  type="time"
                  className="form-control "
                  id="inputHorafinaliza"
                  name="horafinaliza"
                  onChange={handleChange}
                  value={servicioActual.horafinaliza}
                ></input>
              </div>
            </div>
          </div>
          {/* Sepelio */}
          <div className="form-group">
            <label htmlFor="exampleSelect2">
              <strong>Sepelio</strong>
            </label>
            <div className="row">
              <div className="col-sm-12">
                <label
                  className="col-form-label"
                  htmlFor="selectDeptoCementerio"
                >
                  Departamento
                </label>
                <select
                  className="form-control"
                  id="selectDeptoCementerio"
                  name="deptoCementerio"
                  value={deptoNecropolis}
                  onChange={handleChangeDeptoCementerio}
                >
                  {deptos.map((depto) => {
                    return <option>{depto}</option>;
                  })}
                </select>
              </div>
              <div className="col-sm-12">
                <label className="col-form-label" htmlFor="selectCementerio">
                  Cementerio
                </label>
                <select
                  className="form-control"
                  id="selectCementerio"
                  name="cementerio"
                  value={servicioActual.cementerio}
                  onChange={handleChange}
                >
                  {necropolis &&
                    necropolis.length > 0 &&
                    necropolis.map((necro) => {
                      if (deptoNecropolis === necro.departamento) {
                        return (
                          <option>
                            {necro.departamento} - {necro.name}
                          </option>
                        );
                      }
                    })}
                  <option>Cremación</option>
                  <option>Otro</option>
                </select>
              </div>
              <div className="col-sm-6">
                <label className="col-form-label" htmlFor="inputFechasepelio">
                  Fecha
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="inputFechasepelio"
                  name="fechasepelio"
                  value={servicioActual.fechasepelio}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="col-sm-6">
                <label className=" col-form-label" htmlFor="inputHorasepelio">
                  Hora
                </label>
                <input
                  type="time"
                  className="form-control "
                  id="inputHorasepelio"
                  name="horasepelio"
                  onChange={handleChange}
                  value={servicioActual.horasepelio}
                ></input>
              </div>
            </div>
          </div>
          {/* EMPRESA */}
          {userData &&
            userData._userData &&
            userData._userData.rol === "admin" && (
              <div className="form-group">
                <label htmlFor="exampleSelect2">
                  <strong>Empresa</strong>
                </label>
                <div className="col-sm-12">
                  <label>Nombre</label>
                </div>
                <div className="form-group">
                  {userData &&
                    userData._userData &&
                    userData._userData.rol === "admin" && (
                      <input
                        className="form-control"
                        id="inputEmpresa"
                        name="empresa"
                        value={servicioActual.empresa}
                        onChange={handleChange}
                      ></input>
                    )}
                </div>
              </div>
            )}
          {/*NOTA*/}
          <div className="form-group">
            <label htmlFor="inputNombre">
              <strong>Nota</strong>
            </label>
            <input
              type="text"
              className="form-control"
              id="inputNota"
              name="nota"
              value={servicioActual.nota}
              onChange={handleChange}
              required
            ></input>
          </div>

          <Row className="p-3"></Row>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={toggleEditModal}>
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={updateServicioActual}>
            Modificar
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalInfo} toggle={toggleInfoModal}>
        <ModalHeader toggle={toggleInfoModal}>
          <strong className="p-3">Información del servicio</strong>
        </ModalHeader>
        <ModalBody>
          {/* Nombre  */}

          <label htmlFor="inputNombre">
            <strong>Nombre: </strong>
            {" " + servicioActual.nombre}
          </label>

          <div className="form-group">
            <label htmlFor="inputFechafallecimiento">
              <strong>Fecha deceso: </strong>
              {moment(servicioActual.fehcafallecimiento).format("LL")}
            </label>
          </div>

          {/* Empresa funebre */}

          {/* Sala */}

          <div className="form-group">
            <label htmlFor="selectEmpresa">
              <strong>Empresa:</strong> {servicioActual.empresa},{" "}
              {servicioActual.sala}
            </label>
          </div>

          <div className="form-group">
            <label className=" col-form-label">
              <strong>Fecha comienzo: </strong>
              {moment(servicioActual.fechainicio).format("LL")} ,{" "}
              {servicioActual.horainicio}
            </label>
          </div>
          <div className="form-group">
            <label className=" col-form-label">
              <strong>Fecha finalización: </strong>
              {moment(servicioActual.fechafinaliza).format("LL")} ,{" "}
              {servicioActual.horafinaliza}
            </label>
          </div>

          {/* Sepelio */}

          <div className="form-group">
            <label className="col-form-label" htmlFor="selectCementerio">
              <strong>Cementerio: </strong>
              {servicioActual.cementerio}
            </label>
          </div>
          <div className="form-group">
            <label className="col-form-label" htmlFor="inputFechasepelio">
              <strong>Fecha: </strong>{" "}
              {moment(servicioActual.fechasepelio).format("LL")} ,{" "}
              {servicioActual.horasepelio}
            </label>
          </div>
          <label htmlFor="inputNota">
            <strong>Nota: </strong>
            {servicioActual.nota && servicioActual.nota}
          </label>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={toggleInfoModal}>
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={editarServicioActual}>
            Modificar
          </button>
        </ModalFooter>
      </Modal>
      {!user && goHome()}
      {user && (
        <div>
          <div className="p-2">
            {/* SEARCH BOX */}
            <div className="form-group d-lg-none">
              <input
                className="form-control "
                name="search"
                type="text"
                placeholder="Buscar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            <div className="d-flex justify-content-between">
              <button onClick={goForm} className="btn btn-primary">
                Nuevo Servicio
              </button>
              {/* SEARCH BOX */}
              <div className="d-none d-lg-block d-xl-block">
                <form className="form-inline">
                  <input
                    className="form-control"
                    name="search"
                    type="text"
                    placeholder="Buscar"
                    value={search}
                    onChange={handleSearchChange}
                  />
                </form>
              </div>
            </div>
          </div>
          {/* OBITUARY LIST */}
          {/* DESKTOP */}
          <div className="d-none d-lg-block d-xl-block">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Registro</th>
                  <th scope="col">Nombre</th>
                  {!tweens && <th scope="col">Sala</th>}
                  {!tweens && <th scope="col">Sepelio</th>}
                  {tweens && <th scope="col">Activo</th>}
                  {tweens && <th scope="col">By</th>}
                  {tweens && <th scope="col">Empresa</th>}
                  <th className="myTd" scope="col">
                    Ver
                  </th>
                  <th className="myTd" scope="col">
                    Agregar
                  </th>
                  <th className="myTd" scope="col">
                    Modificar
                  </th>
                  <th className="myTd" scope="col">
                    Borrar
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((servicio) => {
                  return (
                    <tr className="table-light" key={servicio.id}>
                      <td>
                        {moment(servicio.fecharegistro.toDate()).format("lll")}
                      </td>
                      <td>{servicio.nombre}</td>

                      {!tweens && <td>{servicio.sala}</td>}
                      {!tweens && <td>{servicio.cementerio}</td>}
                      {tweens && (servicio.deleted ? <td>NO</td> : <td>SI</td>)}
                      {tweens && (
                        <td>
                          [{servicio.empresaid}] {servicio.username}
                        </td>
                      )}
                      {tweens && <td>{servicio.empresa}</td>}
                      <td className="myTd">
                        <h3>
                          <AiOutlineInfoCircle
                            className="myButton"
                            onClick={() => infoService(servicio)}
                          />
                        </h3>
                      </td>
                      <td className="myTd">
                        <h3>
                          <AiOutlineFileAdd
                            className="myButton"
                            onClick={() => addObituary(servicio)}
                          />
                        </h3>
                      </td>
                      <td className="myTd">
                        <h3>
                          <AiOutlineEdit
                            className="myButton"
                            onClick={() => editService(servicio)}
                          />{" "}
                        </h3>
                      </td>
                      <td className="myTd">
                        <h3>
                          <AiOutlineDelete
                            className="myButton"
                            onClick={() => deleteService(servicio.id)}
                          />
                        </h3>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* SMALL DEVICE SCREEN */}
          <div className="form-group d-lg-none">
            {currentPosts.map((servicio) => {
              return (
                <ServiceCard
                  key={servicio.id}
                  tweens={tweens}
                  servicio={servicio}
                  infoService={infoService}
                  editService={editService}
                  deleteService={deleteService}
                  addObituary={addObituary}
                />
              );
            })}
          </div>
          {/*PAGINATION*/}
          <Row className="d-flex justify-content-center">
            <PagNumber
              postsPerPage={postsPerPage}
              totalPosts={list.length}
              paginate={paginate}
              currentPage={currentPage}
            ></PagNumber>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ServicesPosts;
