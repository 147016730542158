import React, { useState, useEffect } from "react";
import "firebase/firestore";
import { useFirebaseApp } from "reactfire";
import moment from "moment/min/moment-with-locales";
import { FaCross, FaStarOfDavid, FaRibbon } from "react-icons/fa";
import { GiShutRose } from "react-icons/gi";

const Ob_admin = (props) => {
  // STATUS: Array de obituarios
  const [obituarys, setObituarys] = useState([]);
  // STATUS: Array de servicios
  const [deceased, setDeceased] = useState([]);

  // Set foramto de fecha y hora
  moment.locale("es");
  // Variable para el acceso a datastore
  const db = useFirebaseApp().firestore();

  const isTodayService = (servicio) => {
    /*****************************************************
     * CHANGE 4
     */
    return true;
    // const fecha = moment(Date.now()).format("DDMMYYYY");

    // const fechafallecimiento = moment(servicio.fehcafallecimiento).format(
    //   "DDMMYYYY"
    // );

    // const fechainicio = moment(servicio.fechainicio).format("DDMMYYYY");

    // const fechafinaliza = moment(servicio.fechafinaliza).format("DDMMYYYY");

    // const fechasepelio = moment(servicio.fechasepelio).format("DDMMYYYY");

    // const fecharegistro = moment(servicio.fecharegistro.toDate()).format(
    //   "DDMMYYYY"
    // );

    // const result =
    //   fecha === fecharegistro ||
    //   fecha === fechafallecimiento ||
    //   fecha === fechainicio ||
    //   fecha === fechafinaliza ||
    //   fecha === fechasepelio;

    // return result;
  };
  /**********************************
   * funcion que obtiene los datos de los Obituarios
   */
  const getObituary = () => {
    db.collection("obituary")
      .orderBy("fecharegistro", "desc")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          if (
            doc.data().paymentStatus === "approved" &&
            /*******************************************************************
             * CHANGE 3
             */
            doc.data().empresaid === "0" &&
            !doc.data().deleted &&
            isTodayService(doc.data())
          ) {
            docs.push({ ...doc.data(), id: doc.id });
          }
        });
        setObituarys(docs);
        setMax(docs.length);
      });
  };

  const loadDeseaced = async (deceasedId) => {
    try {
      const doc = await db.collection("deceased").doc(deceasedId).get();
      return { ...doc.data(), id: deceasedId };
    } catch (error) {}
  };

  /******************
   * Seteamos el titulo para el banner
   */
  useEffect(() => {
    const setmain = props.setHeader;
    setmain(false);
  }, []);

  /******************
   * Obtener el listado de Obituarios
   */
  useEffect(() => {
    getObituary();
  }, []);

  const [seconds, setSeconds] = useState(0);
  const [max, setMax] = useState(0);

  /*********************************************
   * Obtiene el listado de servicios y generea el banner Dinamico de servicios
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => (seconds < max - 1 ? seconds + 1 : 0));
    }, 5000);
    return () => clearInterval(interval);
  }, [max]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    className: "center",
    //centerMode: true,

    //centerPadding: "60px",
    speed: 800,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <div class="in-center navbar-expand-lg navbar-dark bg-primary">
        <div className="footer-copyright text-center text-white navbar-dark bg-primary py-1">
          <h3> {obituarys[0] && obituarys[0].empresa.toUpperCase()}</h3>
        </div>
      </div>
      {obituarys.length > 0 && (
        <div className="container-fluid pl-3 pr-3 abs0-center">
          <div className="card border-dark mb-3">
            <div className="card-header display-2">
              <div>
                <div className="d-flex bd-highlight mb-3">
                  <div className="p-2 bd-highlight ">
                    {obituarys[seconds].ico === "FaCross" && <FaCross />}
                    {obituarys[seconds].ico === "FaStarOfDavid" && (
                      <FaStarOfDavid />
                    )}
                    {obituarys[seconds].ico === "GiShutRose" && <GiShutRose />}
                    {obituarys[seconds].ico === "FaRibbon" && <FaRibbon />}
                  </div>
                  <div className="p-2 bd-highlight">
                    {" "}
                    {obituarys[seconds].nombre}
                  </div>
                </div>
                {/* <i className="material-icons">add</i> */}{" "}
              </div>
            </div>
            <div className="card-body display-4">{obituarys[seconds].obit}</div>
            <div className="card-body display-5">
              {obituarys[seconds].empresa}
            </div>
          </div>
        </div>
      )}
      <div class="in-center navbar-expand-lg navbar-dark bg-primary">
        <div className="footer-copyright text-center text-white navbar-dark bg-primary py-1">
          © 2020 PoweredBy EnMemoria
          <div className="text-center text-white navbar-dark bg-primary py-1">
            <a className="link" href="https://enmemoria.com.uy">
              {" - "}
              https://enmemoria.com.uy {" - "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

/*************************************************************
 * CHANGE 2
 */
export default Ob_admin;
